export enum RESERVATION_TYPE {
  RESERVE = 1,
  TASK = 2,
  PUBLIC_HOLIDAY_AND_OTHERS = 3,
}

export enum GENDER_TYPE {
  MALE = 0,
  FEMALE = 1,
}

export enum CANCELLATION_TYPE {
  CONTACTED = 1,
  NO_CONTACT = 2,
  AFTER_RECEPTION = 3,
  COURTYARDS_ARE_ALL_CLOSED = 4,
}

export enum RESERVATION_STATUS {
  NOT_CONFIRMED = 1,
  CONFIRMED = 2,
  RECEPTION = 3,
  TOTAL = 4,
  ACCOUNTED = 5,
  CANCEL = 6,
}

export enum CONSULTATION_CLASSIFICATION {
  FIRST_VISIT = 1,
  CONTINUOUS_TREATMENT = 2,
  RE_EXAMINATION = 3,
  CONFIRMATION_REQUIRED = 4,
}

export enum SYMPTOMS {
  DAILY_PAIN = 1,
  TRAFFIC_ACCIDENT = 2,
}

export enum SAME_PERSON_CONFIRMATION {
  CONFIRMATION_REQUIRED = 0,
  CONFIRMED = 1,
}

export enum RESERVATION_ROUTE {
  TELEPHONE = 1,
  LINE = 2,
  WALK_IN = 3,
  AFTER_TREATMENT = 4,
}

export enum TASK_STATUS {
  UNFINISHED = 1,
  COMPLETE = 2,
  CANCEL = 3,
}

export enum PUBLIC_STATUS {
  CONFIRM = 1,
  CANCEL = 0,
}

export enum TIME_FRAME {
  FIFTEEN = 1,
  THIRTY = 2,
  SIXTY = 3,
  // SIXTY = 4,
  // SEVENTY_FIVE = 5,
  // NINTY = 6,
  // HUNDRED_FIVE = 7,
  // HUNDRED_TWENTY = 8,
}

export enum STAGE {
  STAGE_ZERO = 0,
  STAGE_ONE = 1,
  STAGE_TWO = 2,
  STAGE_THREE = 3,
  STAGE_FOUR = 4,
  STAGE_FIVE = 5,
}

export enum COUPON_PAYMENT_METHODS {
  STOREFRONT = 1,
  COUPON_EXCHANGE = 2,
  ADJUSTMENT = 3,
  DATA_MIGRATION = 4,
}

export enum MEMBER_PAYMENT_METHODS {
  STOREFRONT = 1,
  MEMBERSHIP_FEE_PAY = 2,
  ADJUSTMENT = 3,
  DATA_MIGRATION = 4,
}

export enum CUSTOMER_QUESTIONNAIRE_EXISTS {
  QUESTIONNAIRE = 1,
  TRAFFIC_ACCIDENT_QUESTIONNAIRE = 2,
  NO_QUESTIONNAIRE_PRESENT = 3,
}
