
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import Multiselect from "@vueform/multiselect";
import DatePicker from "../../components/DatePicker.vue";
import { store } from "@/core/store";
import { fetch } from "@/core/api/api-service";
import moment from "moment";
import {
  CANCELLATION_TYPE,
  CONSULTATION_CLASSIFICATION,
  RESERVATION_TYPE,
  RESERVATION_STATUS,
  SAME_PERSON_CONFIRMATION,
  TASK_STATUS,
  SYMPTOMS,
  RESERVATION_ROUTE,
  TIME_FRAME,
} from "@/core/store/enums";
import { useRoute, useRouter } from "vue-router";
import check from "@/assets/icons/check-solid.svg";
import { POSITION, useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { getCurrentDivision } from "@/core/utils";
import i18n from "@/i18n";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "TodayListSearchB",
  emits: ["customer", "getPractioner"],
  components: {
    Multiselect,
    DatePicker,
  },

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const i18n = useI18n();
    let showCalendar = ref(false);
    let userData = ref();
    let showCalendarStart = ref(false);
    let showCalendarEnd = ref(false);
    let showReservationRegDateTo = ref(false);
    let showReservationRegDateFrom = ref(false);
    let reservationValue = ref({
      NOT_CONFIRMED: false,
      CONFIRMED: false,
      RECEPTION: false,
      TOTAL: false,
      ACCOUNTED: false,
      CANCEL: false,
    });
    let errors: any = ref([]);
    let customer = ref({
      practitioner: "",
      customerName: route.params.customerName,
      store: route.params.store as any,
      customerNumber: route.params.customerNumber,
      phoneNumber: route.params.phoneNumber,
      consultationClassification: "",
      reservationDate2:
        route?.query?.type === "today"
          ? moment().format("YYYY-MM-DD")
          : route?.query?.type === "history"
          ? moment().subtract(1, "day").format("YYYY-MM-DD")
          : "",
      reservationDate1:
        route?.query?.type === "today"
          ? moment().format("YYYY-MM-DD")
          : route?.query?.type === "history"
          ? ""
          : moment().add(1, "day").format("YYYY-MM-DD"),
      noOfHospitalVisitFrom: "",
      noOfHospitalVisiTo: "",
      symptom: "",
      treatment_menu_id: "",
      reservationStatus: "",
      reservationRoute: 0,
      reservationRegDateTo: "",
      reservationRegDateFrom: "",
      medicalRecord: false,
    });
    let storeOptions = ref();
    let treatmentOptions = ref();
    let employeeData = ref();
    let treatmentApiRes = ref();
    let practitionerOptions = ref();
    let reservationRoute = ref([
      {
        value: 0,
        name: ``,
        icon: check,
      },
      {
        value: 1,
        name: `電話`,
        icon: check,
      },
      {
        value: 2,
        name: `ライン`,
        icon: check,
      },
      {
        value: 3,
        name: `飛び込み`,
        icon: check,
      },
      {
        value: 4,
        name: `${i18n.t("Form.AfterTreatment")}`,
        icon: check,
      },
    ]);

    onMounted(() => {
      getCurrentUserData();
      getStores();
      getTreatment();
      storeSelectMounted();
    });

    watchEffect(() => {
      (customer.value.reservationDate1 =
        route?.query?.type === "today"
          ? moment().format("YYYY-MM-DD")
          : route?.query?.type === "history"
          ? ""
          : moment().add(1, "day").format("YYYY-MM-DD")),
        (customer.value.reservationDate2 =
          route?.query?.type === "today"
            ? moment().format("YYYY-MM-DD")
            : route?.query?.type === "history"
            ? moment().subtract(1, "day").format("YYYY-MM-DD")
            : ""),
        (reservationValue.value = {
          NOT_CONFIRMED:
            route?.query?.type === undefined
              ? true
              : route?.query?.type === "history" ||
                route?.query?.type === "today"
              ? true
              : false,
          CONFIRMED:
            route?.query?.type === undefined
              ? true
              : route?.query?.type === "history" ||
                route?.query?.type === "today"
              ? true
              : false,
          RECEPTION:
            route?.query?.type === "history" || route?.query?.type === "today"
              ? true
              : false,
          TOTAL:
            route?.query?.type === "history" || route?.query?.type === "today"
              ? true
              : false,
          ACCOUNTED:
            route?.query?.type === "history" || route?.query?.type === "today"
              ? true
              : route.params.store
              ? true
              : false,
          CANCEL: route?.query?.type === "today" ? true : false,
        });
      // (customer.value.store= route.params.store ? route.params.store : userData.value?.currentAssignment[0].storeId),
      //     (customer.value.practitioner= practitionerOptions.value?.[0].value)
    });

    watch(
      () => route?.query,
      function () {
        if (router && route) {
          reservationValue.value = {
            NOT_CONFIRMED: false,
            CONFIRMED: false,
            RECEPTION: false,
            TOTAL: false,
            ACCOUNTED: true,
            CANCEL: false,
          };

          customer.value = {
            practitioner: "",
            customerName: route.params.customerName,
            store: route.params.store
              ? route.params.store
              : store?.getters?.user?.currentAssignment[0]?.storeId
              ? store?.getters?.user?.currentAssignment[0]?.storeId
              : "",
            customerNumber: route.params.customerNumber,
            phoneNumber: route.params.phoneNumber,
            consultationClassification: "",
            reservationDate2:
              route?.query?.type === "today"
                ? moment().format("YYYY-MM-DD")
                : route?.query?.type === "history"
                ? moment().subtract(1, "day").format("YYYY-MM-DD")
                : "",
            reservationDate1:
              route?.query?.type === "today"
                ? moment().format("YYYY-MM-DD")
                : route?.query?.type === "history"
                ? ""
                : moment().add(1, "day").format("YYYY-MM-DD"),
            noOfHospitalVisitFrom: "",
            noOfHospitalVisiTo: "",
            symptom: "",
            treatment_menu_id: "",
            reservationStatus: "",
            reservationRoute: reservationRoute.value[0]?.value,
            reservationRegDateTo: "",
            reservationRegDateFrom: "",
            medicalRecord: false,
          };
          storeSelectMounted();
        }
      },
      {}
    );

    const getCurrentUserData = () => {
      fetch(`api/v1/getEmployee`).then((res: any) => {
        if (res) {
          const user = res?.data?.data;
          user.currentEmployeeDivision = getCurrentDivision(
            user.employeeDivsions
          );
          userData.value = user;
        }
      });
    };

    const getStores = () => {
      fetch(`api/v1/getStores`).then((res) => {
        if (res) {
          const storeRes = res.data.data;
          storeOptions.value = storeRes
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((store: any) => {
              return {
                value: store.id,
                name: store.storeName,
                storeBusinessHours: store.storeBusinessHours,
                timeFrame: store.timeFrame,
                icon: check,
              };
            });
          storeOptions.value.unshift({ value: "0", name: "", icon: check });
          const storeID = route?.params?.store
            ? route?.params?.store
            : store?.getters?.user?.currentAssignment[0]?.storeId === undefined
            ? ""
            : store?.getters?.user?.currentAssignment[0]?.storeId
            ? store?.getters?.user?.currentAssignment[0]?.storeId
            : "";
          customer.value.store = storeID;
        }
      });
    };

    const storeSelectEvent = (evt: any) => {
        customer.value.store = evt;
      customer.value.practitioner = "";
      practitionerOptions.value = [];
      fetch(`api/v1/storeEmployee`)
        .then((res) => {
          const practionerRes = res.data.data;
          employeeData.value = practionerRes;
          return practionerRes;
        })
        .then((employeeData: any) => {
          if (customer.value.store) {
            const filteredPractitionerWithStoreId: any = employeeData.filter(
              (prSt: any) => prSt.storeId == evt
            );
            if (filteredPractitionerWithStoreId.length > 0) {
              let resources =
                filteredPractitionerWithStoreId[0].employeeInfo.map(
                  (employeeData: any, index: number) => {
                    return {
                      value: employeeData?.employeeId,
                      name: employeeData?.employeeName,
                      icon: check,
                      employeeEndDate: employeeData?.employeeEndDate,
                      employeeStartDate: employeeData?.employeeStartDate,
                    };
                  }
                );
              const empDateAndTime: any = resources.filter(
                (emp: any) =>
                  moment(`${emp?.employeeEndDate}`).isSameOrAfter(
                    `${moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}`,
                    "day"
                  ) &&
                  moment(`${emp?.employeeStartDate}`).isSameOrBefore(
                    `${moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}`,
                    "day"
                  )
              );
              practitionerOptions.value = empDateAndTime;
              practitionerOptions.value.unshift({
                value: "0",
                name: "",
                icon: check,
              });
              customer.value.practitioner = "0"
              //   practitionerOptions.value?.[1]?.value;
            } else {
              practitionerOptions.value = [];
              practitionerOptions.value.unshift({
                value: "0",
                name: "",
                icon: check,
              });
              customer.value.practitioner = "0"
            }
          }
        });
    };

    const storeSelectMounted = () => {
      practitionerOptions.value = [];
      customer.value.practitioner = "";
      fetch(`api/v1/storeEmployee`)
        .then((res) => {
          const practionerRes = res.data.data;
          employeeData.value = practionerRes;
          return practionerRes;
        })
        .then((employeeData: any) => {
          const storeID = route?.params?.store
            ? route?.params?.store
            : store?.getters?.user?.currentAssignment[0]?.storeId === undefined
            ? ""
            : store?.getters?.user?.currentAssignment[0]?.storeId
            ? store?.getters?.user?.currentAssignment[0]?.storeId
            : "";

          if (storeID) {
            const filteredPractitionerWithStoreId: any = employeeData.filter(
              (prSt: any) => prSt.storeId == storeID
            );

            if (filteredPractitionerWithStoreId.length > 0) {
              let resources =
                filteredPractitionerWithStoreId[0].employeeInfo.map(
                  (employeeData: any, index: number) => {
                    return {
                      value: employeeData?.employeeId,
                      name: employeeData?.employeeName,
                      icon: check,
                      employeeEndDate: employeeData?.employeeEndDate,
                      employeeStartDate: employeeData?.employeeStartDate,
                    };
                  }
                );

              const empDateAndTime: any = resources.filter(
                (emp: any) =>
                  moment(`${emp?.employeeEndDate}`).isSameOrAfter(
                    `${moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}`,
                    "day"
                  ) &&
                  moment(`${emp?.employeeStartDate}`).isSameOrBefore(
                    `${moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}`,
                    "day"
                  )
              );
              practitionerOptions.value = empDateAndTime;
              practitionerOptions.value.unshift({
                value: "0",
                name: "",
                icon: check,
              });
              customer.value.practitioner = "0"
              //   practitionerOptions.value?.[0]?.value;
            } else {
              practitionerOptions.value = [];
              practitionerOptions.value.unshift({
                value: "0",
                name: "",
                icon: check,
              });
              customer.value.practitioner = "0"
            }
          }
        });
    };
    const getTreatment = () => {
      fetch(`api/v1/getTreatmentMenu`).then((res) => {
        const TreatRes = res.data.data;

        treatmentApiRes.value = TreatRes;
      });
    };

    const resetValueonClick = (event: any) => {
      customer.value.treatment_menu_id = "";
      treatmentOptions.value = [];
      if (event === '0'){
        const here = treatmentApiRes.value.map(
            (treatData:any) => {
              return {
                value:treatData.id,
                name: treatData.name,
                color: treatData.backgroundColor
                    ? treatData.backgroundColor.toLowerCase()
                    : "",
                consultationClassification: treatData.consultationClassification,
                requiredTime: treatData?.requiredTime,
              };
            }
        );
        treatmentOptions.value = here
        treatmentOptions.value.unshift({ value: "", name: "" });
        customer.value.treatment_menu_id = "";

        // customer.value.treatment_menu_id = resources[0]?.value;
      }else{
        const filteredTreatment: any = treatmentApiRes.value.filter(
            (trData: any) => trData.consultationClassification == event
        );

        if (filteredTreatment.length > 0) {
          let resources = filteredTreatment
              .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
              .map((treatData: any) => {
                return {
                  value: treatData.id,
                  name: treatData.name,
                  color: treatData.backgroundColor
                      ? treatData.backgroundColor.toLowerCase()
                      : "",
                  consultationClassification: treatData.consultationClassification,
                  requiredTime: treatData?.requiredTime,
                };
              });
          treatmentOptions.value = resources;
          treatmentOptions.value.unshift({ value: "", name: "" });
          customer.value.treatment_menu_id = resources[0]?.value;
        } else {
          // customer.value.treatment_menu_id = "";
          treatmentOptions.value = [];
          treatmentOptions.value.unshift({ value: "", name: "" });
          customer.value.treatment_menu_id = "";
        }
      }

    };
    const OnSearch = async () => {
      errors.value = [];
      let formData: any = customer?.value;
      if (formData.noOfHospitalVisitFrom && formData.noOfHospitalVisiTo) {
        if (
          Number(formData.noOfHospitalVisitFrom) >
          Number(formData.noOfHospitalVisiTo)
        ) {
          errors.value.push({
            title: "noOfHospitalVisitFrom",
            message: i18n.t("Errors.E063"),
          });
        }
      }

      if (formData.phoneNumber) {
        const getFirstCharacterInNumberTelephone = formData.phoneNumber;
        if (getFirstCharacterInNumberTelephone.toString().charAt(0) !== "0") {
          errors.value.push({
            title: "phoneNumber",
            message: i18n.t("Errors.E063"),
          });
        }
      }
      if (formData.reservationDate1 && formData.reservationDate2) {
        if (
          moment(formData.reservationDate1).isAfter(formData.reservationDate2)
        ) {
          errors.value.push({
            title: "reservationDate1",
            message: i18n.t("Errors.E063"),
          });
        }
      }
      if (formData.reservationRegDateFrom) {
        // errors.value = "は必須です";
        if (moment(formData.reservationRegDateFrom).isAfter(moment())) {
          errors.value.push({
            title: "reservationRegDateFrom",
            message: i18n.t("Errors.E063"),
          });
        }
      }
      if (formData.reservationRegDateTo) {
        // errors.value = "は必須です";
        if (moment(formData.reservationRegDateTo).isAfter(moment())) {
          errors.value.push({
            title: "reservationRegDateTo",
            message: i18n.t("Errors.E063"),
          });
        }
      }
      formData.reservationStatusMultiple = reservationValue;
      if (errors.value.length === 0) {
        if (
          reservationValue.value.NOT_CONFIRMED === true ||
          reservationValue.value.CONFIRMED === true ||
          reservationValue.value.RECEPTION === true ||
          reservationValue.value.TOTAL === true ||
          reservationValue.value.ACCOUNTED === true ||
          reservationValue.value.CANCEL === true
        ) {
          emit("customer", formData);
        } else {
          toast.error(`予約状態を選択してください`, {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT,
          });
        }
      }
    };

    const filterErrors = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter(
          (x: { title: any }) => x.title === fieldName
        );
        return errorMessage[0]?.message;
      }
    };

    const filterErrorColor = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter(
          (x: { title: any }) => x.title === fieldName
        );
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    };

    const reservationChange = (event: any, eventType: any) => {
      customer.value.reservationStatus = event;
      if (event === "1" && eventType?.target._modelValue === false) {
        reservationValue.value.NOT_CONFIRMED = true;
      } else if (event === "1" && eventType?.target._modelValue === true) {
        reservationValue.value.NOT_CONFIRMED = false;
      } else if (event === "2" && eventType?.target._modelValue === false) {
        reservationValue.value.CONFIRMED = true;
      } else if (event === "2" && eventType?.target._modelValue === true) {
        reservationValue.value.CONFIRMED = false;
      } else if (event === "3" && eventType?.target._modelValue === false) {
        reservationValue.value.RECEPTION = true;
      } else if (event === "3" && eventType?.target._modelValue === true) {
        reservationValue.value.RECEPTION = false;
      } else if (event === "4" && eventType?.target._modelValue === false) {
        reservationValue.value.TOTAL = true;
      } else if (event === "4" && eventType?.target._modelValue === true) {
        reservationValue.value.TOTAL = false;
      } else if (event === "5" && eventType?.target._modelValue === false) {
        reservationValue.value.ACCOUNTED = true;
      } else if (event === "5" && eventType?.target._modelValue === true) {
        reservationValue.value.ACCOUNTED = false;
      } else if (event === "6" && eventType?.target._modelValue === false) {
        reservationValue.value.CANCEL = true;
      } else if (event === "6" && eventType?.target._modelValue === true) {
        reservationValue.value.CANCEL = false;
      }
    };

    const handleNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    };
    const handleNumberRegex = (evt: any, type: any) => {
      const getValue = evt?.target.value;
      var numberPattern = /^[0-9]+$/g;

      const test = numberPattern.test(getValue);

      if (type === "customer.customerNumber") {
        if (test === true) {
          customer.value.customerNumber = getValue;
        } else {
          customer.value.customerNumber = "";
        }
      } else if (type === "customer.phoneNumber") {
        if (test === true) {
          customer.value.phoneNumber = getValue;
        } else {
          customer.value.phoneNumber = "";
        }
      } else if (type === "customer.noOfHospitalVisitFrom") {
        if (test === true) {
          customer.value.noOfHospitalVisitFrom = getValue;
        } else {
          customer.value.noOfHospitalVisitFrom = "";
        }
      } else if (type === "customer.noOfHospitalVisiTo") {
        if (test === true) {
          customer.value.noOfHospitalVisiTo = getValue;
        } else {
          customer.value.noOfHospitalVisiTo = "";
        }
      }
    };

    return {
      filterErrorColor,
      filterErrors,
      handleNumberRegex,
      storeSelectEvent,
      handleNumber,
      reservationChange,
      reservationValue,
      OnSearch,
      showReservationRegDateTo,
      showReservationRegDateFrom,
      reservationRoute,
      RESERVATION_STATUS,
      resetValueonClick,
      treatmentOptions,
      treatmentApiRes,
      SYMPTOMS,
      CONSULTATION_CLASSIFICATION,
      showCalendar,
      showCalendarStart,
      showCalendarEnd,
      practitionerOptions,
      customer,
      showCalendarRegisterStart: false,
      showCalendarRegisterEnd: false,
      storeOptions,
      taskTypeOptions: [
        {
          value: "南区本院",
          name: "南区本院",
          // icon: require("../assets/icons/check-solid.svg"),
        },
        {
          value: "久我の杜院",
          name: "久我の杜院",
          // icon: require("../assets/icons/check-solid.svg"),
        },
        {
          value: "伏見桃山院",
          name: "伏見桃山院",
          // icon: require("../assets/icons/check-solid.svg"),
        },
      ],
    };
  },
});
