export default {
  "LoginPage": {
    "LoginScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Screen"])},
    "SavePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save password"])},
    "ForgetPassword?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password?"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "Sidebar": {
    "ReservationCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Calendar"])},
    "ReservationListToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations Today List"])},
    "ReservationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations List"])},
    "ReservationHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations History"])},
    "CustomerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer List"])},
    "PriceMedical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Medical"])},
    "TaskSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Schedule"])}
  },
  "Calendar": {
    "Details": {
      "Modal": {
        "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are your Sure to change the event"])}
      },
      "SelectedDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday, August 9"])},
      "SelectedTIme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:00～19:00"])},
      "ReservationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Type"])},
      "Classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classsification"])},
      "Symptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptoms"])},
      "Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment"])},
      "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "ReservationRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Route"])},
      "CancleReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancle Reservation"])},
      "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception"])},
      "MedicalAdulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Medical Adulate"])},
      "SecondBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Back"])},
      "Accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting"])},
      "PatientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Name"])},
      "TicketRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Tickets Remaining:"])},
      "MemberTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Member Tickets:"])},
      "Receivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receivable:"])},
      "NoOfVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of visits:"])},
      "AdministrativeNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative Notes"])},
      "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks Column"])}
    },
    "Buttons": {
      "ReservationMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Memo"])}
    },
    "PublicHoliday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Holiday"])}
  },
  "Form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Registration"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practitioner"])},
    "res-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation type"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in)"])},
    "Reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
    "Task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["task"])},
    "PublicHolidaysAndOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public holidays and others"])},
    "ReservationMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation menu"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Notes"])},
    "Finalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalized"])},
    "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "Accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting"])},
    "CheckedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked out"])},
    "Cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
    "CustomerSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer search"])},
    "ReservationPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation person"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone number"])},
    "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First visit"])},
    "OngoingTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing treatment"])},
    "ReExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-examination"])},
    "ToConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm"])},
    "ConsultationClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation classification"])},
    "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment menu"])},
    "DiagnosisDistinction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagnosis distinction"])},
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "Symptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptoms"])},
    "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyday pain"])},
    "Unfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfinished"])},
    "Completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completion"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "TaskType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task type"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])},
    "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["block"])},
    "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure"])},
    "PublicHolidayType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public holiday type"])},
    "NotSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not sure"])},
    "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reception"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "Accounted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounted"])},
    "TreatmentNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment note"])},
    "Acupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acupuncture"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insurance"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident"])},
    "ManagementMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management memo"])},
    "Button": {
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
    },
    "ReservationRouter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Route"])},
    "Telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
    "WalkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walk-In"])},
    "AfterTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After treament"])},
    "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["same person confirmation"])},
    "needConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["need to confirm"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verified"])},
    "CancellationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancellation type"])},
    "Contacted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacted"])},
    "NoContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no contact"])},
    "AfterReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["after reception"])},
    "Closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the courtyards are all closed"])},
    "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])}
  },
  "SearchModal": {
    "AdministrativeNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative Notes"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer number"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone number"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in)"])},
    "CustomerSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer search"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
    "YourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "MonthlyParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly party"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "CellphoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellphone number"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gender"])},
    "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last visit date"])},
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Southern District Main Hospital"])},
    "YamadaIchirou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Ichirou"])},
    "TaroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taro Yamada"])},
    "YamadaHana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Hana"])},
    "IchiroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ichiro Yamada"])},
    "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Taro"])},
    "YamadaFlower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada flower"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
  },
  "ReservationDetails": {
    "buttons": {
      "CreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])},
      "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation details"])},
    "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First visit"])},
    "Yamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada"])},
    "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Taro"])},
    "Mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])},
    "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last visit date"])},
    "HVNotPossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HV not possible"])},
    "NoAcupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No acupuncture"])},
    "MetalHas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metal has"])},
    "DisabledMemberCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled member coupon"])},
    "Sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sheet"])},
    "RemnantCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remnant coupons"])},
    "AccountsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts receivable"])},
    "Circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["circle"])},
    "NoOfVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of hospital visits: "])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])},
    "LastTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last time"])},
    "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reception"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "NextTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next time"])},
    "CancelReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel reservation"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
    "Moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moon"])},
    "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["water"])},
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
    "Demachiyanagi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demachiyanagi"])},
    "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practitioner"])},
    "OnoSister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ono sister"])},
    "ReservationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation status"])},
    "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure"])},
    "CancellationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation type"])},
    "Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Number"])},
    "ReservationPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation person"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone number"])},
    "Tabs": {
      "Reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reserve"])},
      "PreliminaryExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preliminary examination"])},
      "Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inquiry"])},
      "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["policy"])},
      "Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment"])},
      "Personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personal"])}
    },
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "ConsultationClassification": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation classification"])},
      "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First visit"])},
      "OngoingTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing treatment"])},
      "ReExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-examination"])},
      "ToConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm"])}
    },
    "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment menu"])},
    "Minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
    "Symptom": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["symptom"])},
      "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyday pain"])},
      "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident"])}
    },
    "ManagementMemo": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management memo"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo Management memo"])}
    },
    "CustomerInformationRemarks": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information (remarks)"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Customer description items Items Customer items Customer items Customer items Customer items"])}
    },
    "ReservationRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation route"])},
    "AkiraWatanabe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akira Watanabe"])},
    "NoboruYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noboru Yamada"])},
    "AfterTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After treatment"])},
    "TanakaNebula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanaka Nebula"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left arm does not rise 2-3 days ago"])},
    "CreateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])}
  },
  "Questions": {
    "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of symptoms are you worried about (multiple selections)"])},
    "Title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you have symptoms (multiple choices)"])},
    "Title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When did you get symptoms?"])},
    "Title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a pacemaker?"])},
    "Title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there metal in your body?"])},
    "Title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you pregnant or may become pregnant?"])},
    "Title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever been diagnosed with hernia, spinal canal stenosis, sciatica, etc. at the hospital?"])},
    "Title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever had surgery?"])},
    "Title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of symptoms are you worried about (multiple selections)"])},
    "Title10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of symptoms are you worried about (multiple selections)"])},
    "pain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pain"])},
    "Hardened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardened"])},
    "Numb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numb"])},
    "Dull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dull"])},
    "DoesntWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't work"])},
    "Others": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["others"])},
      "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text"])}
    },
    "Head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["head"])},
    "Neck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neck"])},
    "Shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoulder"])},
    "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "Waist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waist"])},
    "Buttocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buttocks"])},
    "Coxa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coxa"])},
    "Arm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arm"])},
    "Elbow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elbow"])},
    "Wrist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrist"])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hand"])},
    "Finger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finger"])},
    "Thigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thigh"])},
    "Knee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["knee"])},
    "Shank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shank"])},
    "Calf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calf"])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foot"])},
    "Ankle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ankle"])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toe"])},
    "MoreThanTen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 10 years ago"])},
    "MoreThanOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 1 year ago"])},
    "SixToThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 months - 3 months ago"])},
    "OneToThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 to 3 months before"])},
    "WithinOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 1 month"])},
    "WithinTwoThreeDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 2-3 days"])},
    "Yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I don't"])},
    "NameOfDiagnosis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of diagnosis"])},
    "KindOfSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of surgery is this?"])},
    "ChronicStiffShoulders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronic stiff shoulders and back pain"])},
    "Fatigue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatigue and injury due to sports"])},
    "InjuriesAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injuries caused by traffic accidents"])},
    "Father": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["father"])},
    "Mother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mother"])},
    "OlderBrother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["older brother"])},
    "YoungerBrother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["younger brother"])},
    "OlderSister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["older sister"])},
    "YoungerSister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["younger sister"])},
    "Husband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["husband"])},
    "Wife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wife"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child"])}
  },
  "ReservationCancelModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Cancellation"])},
    "contacted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacted"])},
    "noContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact"])},
    "afterReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After reception"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The courtyards are all closed"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])}
  },
  "DeletionModal": {
    "deleteScreeningSlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do you want to delete the screening slip?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete it?"])},
    "publishedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published: september 2021, 09"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets (table)"])},
    "advance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advance examination slip (back)"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created by:"])},
    "updaters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updaters:"])},
    "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion"])}
  },
  "Policy": {
    "MainComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main complaint"])},
    "FillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(please fill in)"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text text text text text text text text text text text text text text text text text text text text text text text text text"])},
    "PathologicalBehavior": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pathological Behavior"])},
      "ExcessiveMovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excessive movement"])},
      "TooMuchMovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too much movement"])},
      "Trauma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trauma"])}
    },
    "See": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What I see"])},
    "Finding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finding"])},
    "Cause": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause"])},
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add cause muscles"])},
      "Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place: "])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "Muscle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muscle: "])},
      "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cause muscle removal"])},
      "TRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trp delete"])}
    },
    "Location": {
      "Left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
      "Right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
      "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])}
    },
    "Registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "Deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion"])}
  },
  "TrafficAccident": {
    "CustomerRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
    "SelectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(please select and shoot the file)"])},
    "SelectQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a questionnaire (table)"])},
    "SelectPreExamSlip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a pre-examination slip (back)"])},
    "Registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])}
  },
  "Inquiry": {
    "SelectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(please select and shoot the file)"])},
    "SelectQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(please select and shoot the file)"])},
    "Registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])}
  },
  "Treatment": {
    "MainComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main complaint"])},
    "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress from the last time"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in.)"])},
    "SelectPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（Select Position）"])},
    "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage"])},
    "TodaySymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's symptoms"])},
    "AdditionalSymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional symptoms"])},
    "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location:"])},
    "Sense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sense:"])},
    "MainMenu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main menu"])},
      "HeadAndShoulders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head and shoulders"])},
      "ShoulderJoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoulder joint"])},
      "Forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forearm"])},
      "WaistBag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waist bag"])},
      "WaistFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waist front"])},
      "NumbnessInLowerLimbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbness in the lower limbs"])},
      "NumbnessTnTheSole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbness in the sole"])},
      "LowerLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower leg"])},
      "Tightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tightly"])},
      "WShoulderJoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W shoulder joint"])},
      "WFrontWrist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W front wrist"])},
      "WLowerLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W lower leg"])},
      "LumbarDecubitus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lumbar decubitus"])},
      "LowerBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lower back (esp. the lumbar region)"])},
      "LumbarAbdomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lumbar abdomen"])},
      "LowerBackAndLimbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower back and lower limbs"])},
      "UpperLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper limb band"])},
      "WUpperLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W Upper limb zone"])},
      "LowerLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower limb treatment"])},
      "WLowerLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W Lower limb treatment"])}
    },
    "EffectUpMenu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effect up menu"])},
      "Acupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acupuncture"])},
      "Needles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["needles"])},
      "Pulse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pulse"])}
    },
    "EffectKeepMenu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effect keep menu"])},
      "Yen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yen (-sheets)"])},
      "Tape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tape (-sheets)"])},
      "Zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero (-sheets)"])},
      "CastFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cast fixed"])},
      "BandageChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandage change"])}
    },
    "Action": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action"])},
      "WarmTasting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warm tasting"])},
      "WarmworkBooklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warmwork Booklet"])},
      "StretchBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stretch book"])},
      "Equiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equiten"])},
      "GoogleMapReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google map review"])},
      "Whiteboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whiteboard"])}
    },
    "InsuranceTreatment": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance treatment"])},
      "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident"])}
    },
    "Creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "Toyama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toyama"])},
    "Updater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updater"])},
    "Mao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mao"])}
  },
  "Ticket": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets for Medical Treatment"])},
    "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finished"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "Step1": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter your information to create a medical record"])},
      "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if there is a change in your information, please correct it."])},
      "Phonetic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phonetic"])},
      "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])},
      "Placeholder1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example: or not"])},
      "Placeholder2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example: yamada"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "KanaLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kana last name"])},
      "KanaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kana name"])},
      "KanjiLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanji last name"])},
      "KanjiName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanji name"])},
      "Placeholder3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：山田"])},
      "Placeholder4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example: taro"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "Man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
      "Woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woman"])},
      "DoB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date of birth"])},
      "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
      "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(select)"])},
      "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zip code"])},
      "Prefectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prefectures"])},
      "AddressSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address search"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city and street"])},
      "Building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building name, etc."])},
      "PhoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number"])},
      "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile"])},
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
      "Occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["occupation"])},
      "InvalidPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid postal code"])}
    },
    "Step2": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell us about your symptoms."])}
    },
    "Step3": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please complete the survey"])},
      "Questions": {
        "Q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please choose one of the decisive things that made you come to our hospital."])},
        "Options": {
          "Acquaintance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from an acquaintance"])},
          "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
          "Family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the family"])},
          "Coworkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coworkers at work"])},
          "Flyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flyer"])},
          "HomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our home page"])},
          "SearchTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search terms"])},
          "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text text text text text text"])},
          "GoogleMaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps"])},
          "Equiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equiten"])},
          "Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
          "HotPepper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hot pepper"])},
          "OtherOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other online"])},
          "Appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appearance passing by"])},
          "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
        },
        "Q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may i contact you with a postcard?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
        "Q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["we record counseling contents to improve the service (please check one of them)"])},
        "Allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allow shooting"])},
        "DontAllow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["don't allow shooting"])}
      }
    }
  },
  "PersonalInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About personal information"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text text text text text text text text"])},
    "Agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the above handling of personal information. (Required)"])},
    "Instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After confirming and agreeing to the handling of personal information, click the \"Next\" button below to complete. "])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the next"])}
  },
  "CustomerInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information"])},
    "FuriganaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furigana"])},
    "FuriganaFirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada"])},
    "FuriganaLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taro"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taro"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gender"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
    "Moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moon"])},
    "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "PostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "CityAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City address"])},
    "CityAddressDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nishikujo Nishiyanaginouchi-cho, Minami-ku, Kyoto-shi, Kyoto xx-x"])},
    "NameOfBuildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of buildings etc"])},
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile number"])},
    "HomePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home phone number"])},
    "Profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profession"])},
    "Employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employee"])}
  },
  "CustomerDetails": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Details"])},
    "Details": {
      "ReservationID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservation ID"])},
      "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer number"])},
      "Phonetic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phonetic"])},
      "YourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your name"])},
      "RegisteredStores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registered stores"])},
      "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
      "KanaLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kana last name"])},
      "KanaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kana name"])},
      "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customer name"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
      "NoHyphen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(NoHyphen)"])},
      "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile"])},
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
      "HomeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home phone number"])},
      "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
      "DoB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date of birth"])},
      "ZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zip code"])},
      "Prefecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prefecture"])},
      "CityAndStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city and street"])},
      "Building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building name, etc."])},
      "PersonalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personal information"])},
      "AdminRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrative remarks"])},
      "NoOfVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of hospital visits"])},
      "FirstVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Return Day"])},
      "lastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last visit date"])},
      "ReexaminationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["re-examination date"])},
      "InchargeFirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person in charge of first visit"])},
      "InchargeReexamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person in charge of re-examination"])},
      "FirstDaySelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the first day of self-visit"])},
      "FirstDayCompensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first day of workmen's compensation"])},
      "NotAtAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not at all"])},
      "HVNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HV IS NOT ALLOWED"])},
      "Metals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metals are there"])},
      "MetalParts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metal parts"])},
      "InsuranceAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insurance treatment available"])},
      "Compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with treatment for traffic accidents and workers' compensation"])},
      "AcupunctureAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acupuncture available"])},
      "MemberRemainingCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member remaining coupons"])},
      "RemainingCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining coupons"])},
      "RemainingAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining accounts receivable"])},
      "MonthlyMembershipDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly membership date"])},
      "MonthlyTemporaryRecess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly member temporary recess"])},
      "MonthlyWithdrawlRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly membership withdrawal date"])},
      "InflowPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inflow path"])},
      "Occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
      "SearcTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search terms"])},
      "OtherInflowPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other inflow path"])},
      "Notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notification email permission"])},
      "Shooting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shooting permission"])},
      "EReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review_with ecten"])},
      "GReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVIEWS_G REVIEWS AVAILABLE"])},
      "WBReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_WB REVIEW AVAILABLE"])},
      "ProductSalesByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product sales by type"])},
      "MembershipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membership type"])},
      "MembershipDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membership date"])},
      "MembershipSuspensionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membership suspension date"])},
      "memberSettlementDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member's latest settlement date"])},
      "memberDeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member's latest delivery date"])},
      "FirstPurchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the date of first purchase of goods"])},
      "LatestPurchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product sales latest purchase date"])},
      "EstimatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimated date of product sales and digestion"])},
      "NextAlertDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next alert date for goods sales"])},
      "SalesSettleSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales settlement"])}
    },
    "CumulativePurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cumulative purchases"])},
    "CumulativeUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the number of cumulative uses"])},
    "Remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of times remaining"])},
    "Append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adjust the line append"])},
    "RemainingAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining accounts receivable"])},
    "Treatment": {
      "Complaints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complaints:"])},
      "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["progress since last time:"])},
      "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage:"])},
      "StageZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 0"])},
      "StageOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 1"])},
      "StageTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage 2"])},
      "StageThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 3"])},
      "StageFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 4"])},
      "StageFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 5"])},
      "TodaySymptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today's symptoms:"])},
      "Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place:"])},
      "Sensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sensation:"])},
      "Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main:"])},
      "Muscle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muscle:"])},
      "EffectUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effect up:"])},
      "EffectKeep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effect keep:"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action:"])},
      "InsuranceTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insurance treatment:"])},
      "MusclePart": {
        "ShoulderJoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoulder Joint"])},
        "Forearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forearm"])},
        "WaistBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waist Back"])},
        "WaistFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waist Front"])},
        "NumbLowerLegs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numb Lower Extremities"])},
        "SoleNumbness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sole Numbness"])},
        "Knees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knees"])},
        "LowerLegs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower Legs"])},
        "Tight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tight"])},
        "WShoulderJoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W Shoulder Joint"])},
        "WForearm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W Forearm"])},
        "WLowerLegs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W Lower Legs"])},
        "LumbarLying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lumbar Lying Position"])},
        "HeadAndShoulders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head And Shoulders"])}
      },
      "ActionOptions": {
        "WarmthTasting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warmth tasting"])},
        "WarmthBooklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warmthm booklet"])},
        "StretchBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stretch book"])},
        "Ecten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ecten"])},
        "GoogleMapsReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google maps review"])},
        "whiteboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["whiteboard"])}
      },
      "InsuranceOptions": {
        "InsuranceTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Treatment"])},
        "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traffic accidents and workmen's compensation"])}
      }
    },
    "WarmPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warm plus"])},
    "Vitamins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trigger point vitamins"])},
    "Modal": {
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit adjustment line"])},
      "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of purchases"])},
      "Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the number of usages"])},
      "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remarks"])}
    },
    "Table": {
      "MethodPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
      "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["method of payment"])},
      "ProductMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product menu"])},
      "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of purchases"])},
      "Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the number of usages"])},
      "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remarks"])},
      "Operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operate"])},
      "ExpiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiry date"])},
      "IncreaseAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["increase amount"])},
      "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery date"])},
      "DecreaseAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reduce the amount"])}
    }
  },
  "CheckInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the information entered by the customer"])},
  "ThankYouTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your answer!"])},
  "TodayList": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's list"])},
    "ReservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation date"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Southern District Main Hospital"])},
    "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practitioner"])},
    "PleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please select)"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
    "PleaseEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please enter)"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer number"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in)"])},
    "ConsultationClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation classification"])},
    "FirstVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First visit"])},
    "OngoingTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing treatment"])},
    "ReExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-examination"])},
    "ToConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
    "Symptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["symptom"])},
    "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyday pain"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident"])},
    "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment menu"])},
    "ReservationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation status"])},
    "NotSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not sure"])},
    "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure"])},
    "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reception"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "Accounted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounted"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "ReservationRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation route"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
    "TaskType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Type"])}
  },
  "ReservationStoreSelection": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation store selection"])},
    "VisitHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit history"])},
    "First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first"])},
    "UnderContinuousTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under continuous treatment"])},
    "AfterAWhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["after a while"])},
    "Symptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["symptom"])},
    "EverydayPain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyday pain"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident"])},
    "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["area"])},
    "Kyoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyoto"])},
    "Osaka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaka"])},
    "Shiga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shiga"])},
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
    "PleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please select)"])},
    "ToTheNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the next"])}
  },
  "IdLinkage": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID linkage"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Number"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in)"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "ExampleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: Taro Yamada"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "ExampleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: For January 01, 1999 → 19990101"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gender"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile number"])},
    "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* If you do not know your customer number, please make a reservation by phone."])},
    "ToTheNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the next"])}
  },
  "EnterReservationInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter reservation information"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "ExampleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: Taro Yamada"])},
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile number"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in)"])},
    "SymptomsOfTrouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symptoms of trouble"])},
    "InflowRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inflow route"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])},
    "ToTheNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the next"])}
  },
  "ReservationAcceptanceCompleted": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation acceptance completed"])},
    "ReservationConfirmationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation confirmation number: 12345678"])},
    "ReservationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation details"])},
    "ReservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation date"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 02, 2022 10:00"])},
    "ReservationStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation store"])},
    "Minami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minami-ku Main Hospital"])},
    "TroublesomeSymptoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troublesome Symptoms"])},
    "SymptomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Text Text Text Text Text Text Text Text Text"])},
    "RegisterAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register an appointment in the calendar"])},
    "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "NoteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text Text"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])}
  },
  "DuringMaintenance": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During maintenance"])},
    "Resumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumption"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 02, 2022 at 4:00 am"])},
    "Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is scheduled for"])}
  },
  "TodayListTable": {
    "Head": {
      "ReservationDateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation date and time"])},
      "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
      "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone number"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "TreatmentMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment menu"])},
      "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practitioner"])},
      "ManagementMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management memo"])},
      "ReservationRegistrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation registration date"])}
    },
    "Names": {
      "ErnestWilson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ernest Wilson"])},
      "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Taro"])},
      "Arai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arai"])},
      "Yasuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasuda"])},
      "YamadaHana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Hana"])},
      "KotaroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotaro Yamada"])}
    },
    "Status": {
      "Accounted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounted"])},
      "Reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reception"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
      "Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure"])}
    },
    "Points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
    "TrafficAccident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident / industrial accident (30 minutes)"])},
    "Acupuncture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acupuncture (30 minutes)"])},
    "Toyama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toyama"])},
    "AbeWomanHope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abe woman hope"])},
    "TakeshitaHospitalityCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takeshita hospitality check ..."])}
  },
  "MobileCalendar": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check free slots"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please select your preferred date and time of visit"])},
    "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous week"])},
    "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next week"])},
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([": open"])},
    "FewLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([": only a few left"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([": reception closes"])},
    "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])}
  },
  "Tables": {
    "BeApplicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be applicable"])},
    "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practitioner"])}
  },
  "TaskListTable": {
    "Head": {
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
      "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])},
      "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
      "Practitioner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practitioner"])},
      "Kinds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kinds"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])}
    },
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Southern District Main Hospital"])},
    "Toyama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toyama"])},
    "Takeda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1917 Takeda"])},
    "RookieTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rookie training"])},
    "AcupunctureTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acupuncture training"])},
    "MonthlyReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly report"])},
    "ESConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES conference"])},
    "Unfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfinished"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "CustomerListTable": {
    "Head": {
      "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Number"])},
      "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
      "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
      "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone number"])},
      "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last visit date"])},
      "RemnantCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remnant coupons"])},
      "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])},
      "ManagementPreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management preparation"])}
    },
    "SouthernDistrictMainHospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Southern District Main Hospital"])},
    "ErnestWilson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ernest Wilson"])},
    "YamadaTaro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Taro"])},
    "YamadaHana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yamada Hana"])},
    "KotaroYamada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotaro Yamada"])},
    "TakeshitaHospitalityCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takeshita hospitality check ..."])},
    "TransferredFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred from Kuga no Mori"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])},
    "MedicalRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical record"])}
  },
  "CustomerListSearch": {
    "Store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
    "PleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please select)"])},
    "CustomerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer name"])},
    "PleaseEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please enter)"])},
    "TelephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telephone number"])},
    "PleaseFillIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Please fill in)"])},
    "CustomerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Number"])},
    "BirthMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth month"])},
    "Choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Choice)"])},
    "Moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moon"])},
    "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
    "Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "LastVisitDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last visit date"])},
    "ExampleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: 2021-08-09"])},
    "BaoyeTreatmentHas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baoye treatment has"])},
    "TreatmentAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic accident / industrial accident treatment available"])},
    "NumberOfHospitalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of hospital visits:"])},
    "Times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["times"])},
    "AgeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member:"])},
    "TenTimesOrMoreLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 times or more left"])},
    "ZeroTimesLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 times left"])},
    "RemainingMinus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining minus"])},
    "ProductSalesMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product sales member:"])},
    "UnsettledThisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsettled this month"])},
    "NotDeliveredThisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not delivered this month"])},
    "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review:"])},
    "Equiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equiten"])},
    "GReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G review"])},
    "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["others:"])},
    "ARemainingCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a remaining coupon"])},
    "AccountsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts receivable"])},
    "NonMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-member"])},
    "Anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])}
  },
  "TaskListSearch": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task List"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Unfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfinished"])},
    "Completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completion"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "Return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return"])},
  "PaymentMethods": {
    "storeFront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Front"])},
    "couponExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon Exchange"])},
    "adjustment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustment"])},
    "dataMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Migration"])},
    "memberShipFeePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MemberShip Fee Pay"])}
  },
  "Cancellation": {
    "cancellationContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Contact"])},
    "noCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Cancellation"])},
    "acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Acceptance"])},
    "hospitalConvenience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cancellation Hospital Convenience"])}
  },
  "Errors": {
    "E050": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " exceeds the number of digits that can be registered"])},
    "E051": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " is required"])},
    "E052": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " has characters that cannot be registered"])},
    "E053": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " is incorrect"])},
    "E0541": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter at least for ", _interpolate(_named("field"))])},
    "E055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIN, EFFECT UP, EFFECT KEEP NO INPUT"])},
    "E056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIN NOT SELECTED"])},
    "E070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE ENTER ALL ITEMS"])},
    "E071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTHERS SEEM TO HAVE UPDATED EARLIER. GO BACK TO THE PREVIOUS SCREEN AND REAPPEAR"])},
    "E072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTHER RESERVATIONS SEEM TO HAVE BEEN REGISTERED FIRST. PLEASE REGISTER AGAIN"])},
    "E100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE CORRECT THE CONSULTATION CLASSIFICATION TO EITHER INITIAL VISIT, CONTINUOUS TREATMENT, OR RE-EXAMINATION."])},
    "E101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER INFORMATION MAY HAVE BEEN REGISTERED. PLEASE CHECK"])},
    "E102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE REGISTER CUSTOMER INFORMATION"])},
    "E103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER NAME AND RESERVATION NAME OF CUSTOMER NUMBER ARE DIFFERENT"])},
    "E104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER NUMBER PHONE NUMBER IS DIFFERENT AND RESERVED PHONE NUMBER IS DIFFERENT"])},
    "E105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPOINTMENT ALREADY REGISTERED FOR SPECIFIED DATE, TIME AND PRACTITIONER"])},
    "E106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN THE CASE OF A RESERVATION MEMO, THE TREATMENT CANNOT BE SPECIFIED"])},
    "E107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE SELECT A LINE"])},
    "E108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE SELECT THE RESERVATION STATUS"])},
    "E109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THE SELECTED LINE CANNOT BE CANCELED"])},
    "E110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELECTED LINES CANNOT BE CANCELED AFTER RECEPTION"])},
    "E111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELECTED LINES HAVE BEEN UPDATED"])},
    "E112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH CONDITION ITEM IS NOT ENTERED"])},
    "E113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPLOAD ERROR"])},
    "E114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I CANNOT DELETE IT BECAUSE THE TREATMENT IS REGISTERED"])},
    "E115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER NOT REGISTERED"])},
    "E116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE SELECT THE TRAFFIC ACCIDENT MEDICAL EXAMINATION SLIP TO UPLOAD"])},
    "E117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE SELECT THE QUESTIONNAIRE TO UPLOAD"])},
    "E060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIGITS EXCEEDED"])},
    "E061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REQUIRED"])},
    "E062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHARACTER TYPE DIFFERENCE"])},
    "E063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORMAT DIFFERENCE"])},
    "C100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I DON'T HAVE A RESERVATION FOR THE NEXT TIME WOULD YOU LIKE?"])},
    "C101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT IS TIME TO RECOMMEND THE GOODS SALES OF THE NEXT MONTH"])},
    "C102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULTATION CLASSIFICATION WAS SET AUTOMATICALLY"])},
    "C103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THERE IS ONLY ONE MEMBER COUPON LEFT."])},
    "L100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MULTIPLE RESERVATIONS ARE ACCEPTED BY PHONE"])},
    "L101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WE ACCEPT RESERVATIONS BY PHONE"])},
    "L102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMERS WILL BE RE-EXAMINED"])},
    "L103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER WILL BE CONTINUOUS TREATMENT"])},
    "L104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I AM CURRENTLY IN THE HOSPITAL. IF YOU WOULD LIKE TO SEE AGAIN, PLEASE MAKE A RESERVATION BY PHONE."])},
    "L105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER INFORMATION COULD NOT BE COLLATED. WE ACCEPT RESERVATIONS BY PHONE."])},
    "L106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WE WERE UNABLE TO SECURE A RESERVATION FOR THE SPECIFIED DATE AND TIME. PLEASE SELECT ANOTHER DATE AND TIME"])}
  },
  "BackModal": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to go back?"])}
  },
  "Treatment": {
    "Complaints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complaints:"])},
    "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["progress since last time:"])},
    "Stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage:"])},
    "StageZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 0"])},
    "StageOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 1"])},
    "StageTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage 2"])},
    "StageThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 3"])},
    "StageFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 4"])},
    "StageFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage 5"])},
    "TodaySymptom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today's symptoms:"])},
    "Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place:"])},
    "Sensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sensation:"])},
    "Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main:"])},
    "Muscle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muscle:"])},
    "EffectUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effect up:"])},
    "EffectKeep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effect keep:"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action:"])},
    "InsuranceTreatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insurance treatment:"])}
  }
}