
import { defineComponent, ref, watch, watchEffect, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import { reservationStatus } from '@/core/store/constants';
import { RESERVATION_STATUS } from '@/core/store/enums';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/i18n';
import check from '@/assets/icons/check-solid.svg';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import tickIcon from '@/assets/icons/check-solid.svg';
import { fetch } from '@/core/api/api-service';

export default defineComponent({
  name: 'ReservationListTable',
  components: {
    Multiselect
  },
  emits: ['customer', 'updateStatus', 'filterReservationByPractitioner'],
  props: {
    customerListData: {
      type: Array,
      required: true
    },
    practionerData: {
      type: Array,
      required: true
    },
    selectedTask: {
      type: Boolean,
      required: true
    }
  },

  setup(props: any, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const practitionerOptions = ref<Array<any>>([]);
    const practitionerList = ref<Array<any>>([]);
    const selectedStatus = ref('');
    const employeeData = ref();
    const selectTask = ref<boolean>(false);
    const selected = ref(props.customerListData);

    let sort = ref({
      sortUp1: route?.query?.type !== 'history' ? false : true,
      sortUp2: false,
      sortUp3: false,
      sortUp4: false,
      sortUp5: false,
      sortUp6: false,
      sortUp7: false,
      sortUp8: false
    });
    const selectedPractitioner = ref('ALL');
    let selectedSort = ref({ field: '', sortBy: '' });
    watchEffect(() => {
      selected.value = props.customerListData;
      selectTask.value = props.selectedTask;
    });

    onMounted(() => {
      route?.query?.type === 'history'
        ? (selectedSort.value.field = 'reservationDateTime')
        : (selectedSort.value.field = '');
    });

    watch(
      () => route?.query,
      function () {
        if (router && route) {
          sort.value.sortUp1 = route?.query?.type !== 'history' ? false : true;
          route?.query?.type === 'history'
            ? (selectedSort.value.field = 'reservationDateTime')
            : (selectedSort.value.field = '');
        }
      },
      {}
    );
    const onSort = (evt: any, type: any) => {
      if (type === 'sortUp1') {
        if (sort.value.sortUp1 === true) {
          selectedSort.value = { field: 'reservationDateTime', sortBy: 'ASC' };
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
        } else {
          selectedSort.value = { field: 'reservationDateTime', sortBy: 'DESC' };
          sort.value = {
            sortUp1: true,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
        }
      }
      if (type === 'sortUp2') {
        if (sort.value.sortUp2 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'customerName', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: true,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'customerName', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp3') {
        if (sort.value.sortUp3 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'telephoneNumber', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: true,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'telephoneNumber', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp4') {
        if (sort.value.sortUp4 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'status', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: true,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'status', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp5') {
        if (sort.value.sortUp5 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'treatmentMenu', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: true,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'treatmentMenu', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp6') {
        if (sort.value.sortUp6 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'practitioner', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: true,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'practitioner', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp7') {
        if (sort.value.sortUp7 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'managementMemo', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: true,
            sortUp8: false
          };
          selectedSort.value = { field: 'managementMemo', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp8') {
        if (sort.value.sortUp8 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = {
            field: 'reservationRegDateTime',
            sortBy: 'ASC'
          };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: true
          };
          selectedSort.value = {
            field: 'reservationRegDateTime',
            sortBy: 'DESC'
          };
        }
      }
      emit('customer', selectedSort.value);
    };
    const convertToHH = (date: any) => {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
    };
    const convertToStartingHH = (date: any) => {
      return moment(date, 'HH:mm:ss').format('HH:mm');
    };
    const convertToMMDD = (date: any) => {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD');
    };
    const goToMedical = async (details: any, type: string, event: any) => {
      if (
        details?.reservationStatus === 3 ||
        details?.reservationStatus === 4 ||
        (details?.reservationStatus === 5 && details?.reservationType === 1)
      ) {
        let dateIsSameOrBefore = moment(`${details?.date}`, 'YYYY-MM-DD').isSameOrBefore(
          moment(`${moment().format('YYYY-MM-DD')}`, 'YYYY-MM-DD')
        );
        if (
          details?.reservationStatus === 3 &&
          details?.medicalRecord == null &&
          dateIsSameOrBefore
        ) {
          toast.error('カレンダー画面でカルテ記入ボタンをタップしてください', {
            timeout: 2000,
            position: POSITION.BOTTOM_LEFT
          });
        } else {
          await router.push({
            name: 'ReservationDetail',
            params: {
              id: details?.id
            }
          });
        }
      } else if (
        details?.reservationStatus === 1 ||
        (details?.reservationStatus === 2 && details?.reservationType === 1)
      ) {
        let dateIsPassed = moment(`${details?.date}`, 'YYYY-MM-DD').isSameOrAfter(
          moment(`${moment().format('YYYY-MM-DD')}`, 'YYYY-MM-DD')
        );
        if (dateIsPassed === true) {
          await router.push({
            name: 'ReservationRegistration',
            params: {
              isFromCancel: 'false',
              isFromEdit: 'true',
              hasMedicalRecord: details?.medicalRecord?.id ? 'true' : 'false',
              id: details?.id,
              date: details?.date,
              startHour: moment(details.startingTime, 'HH:mm:ss').format('HH:mm'),
              endHour: moment(details.endingTime, 'HH:mm:ss').format('HH:mm') || '',
              store: details?.store?.id || '',
              practitioner: details.practitioner?.id || '',
              reservation_type: details.reservationType,
              reservation_status: details.reservationStatus,
              reservation_person: details.reservationPerson,
              phone: details.phone,
              same_person_confirmation: details?.samePersonConfirmed ? 1 : 0,
              symptom: details?.symptom,
              management_memo: details?.managementMemo,
              treatment_menu_id: details?.treatmentMenu?.id,
              task_type: details?.taskType?.id,
              consultation_classification: details?.consultationClassification,
              customerNumber: details?.customer?.customerNumber,
              absence_type_id: details?.absenceType?.id,
              status: details?.status,
              detail:
                details.reservationType == 2
                  ? details?.detail
                  : details?.reservationType == 3
                  ? details?.details
                  : '',
              reservationRoute: details?.reservationRoute || '',
              block: details?.block ? '1' : '0'
            },
            query: { type: 'reserve' }
          });
        }
      } else if (details?.reservationStatus === 6 && details?.reservationType === 1) {
        toast.error('キャンセル種別の変更のみが可能', {
          timeout: 2000,
          position: POSITION.BOTTOM_LEFT
        });
        await router.push({
          name: 'ReservationRegistration',
          params: {
            isFromCancel: details?.reservationStatus === 6 ? 'true' : 'false',
            isFromEdit: 'true',
            hasMedicalRecord: details?.medicalRecord?.id ? 'true' : 'false',
            id: details?.id,
            date: details?.date,
            startHour: moment(details.startingTime, 'HH:mm:ss').format('HH:mm'),
            endHour: moment(details.endingTime, 'HH:mm:ss').format('HH:mm') || '',
            store: details?.store?.id || '',
            practitioner: details.practitioner?.id || '',
            reservation_type: details.reservationType,
            reservation_status: details.reservationStatus,
            reservation_person: details.reservationPerson,
            phone: details.phone,
            same_person_confirmation: details?.samePersonConfirmed ? 1 : 0,
            symptom: details?.symptom,
            management_memo: details?.managementMemo,
            treatment_menu_id: details?.treatmentMenu?.id,
            task_type: details?.taskType?.id,
            consultation_classification: details?.consultationClassification,
            customerNumber: details?.customer?.customerNumber,
            absence_type_id: details?.absenceType?.id,
            status: details?.status,
            detail:
              details.reservationType == 2
                ? details?.detail
                : details?.reservationType == 3
                ? details?.details
                : '',
            reservationRoute: details?.reservationRoute || '',
            block: details?.block ? '1' : '0'
          },
          query: { type: 'reserve' }
        });
      }
    };

    const cancellationOptions = [
      {
        value: 1,
        name: `キャンセル連絡あり`,
        selected: true
        // icon: require("../assets/icons/check-solid.svg"),
      },
      {
        value: 2,
        name: ` キャンセル連絡なし`,
        selected: false
        // icon: require("../assets/icons/check-solid.svg"),
      },
      {
        value: 3,
        name: ` キャンセル受付後`,
        selected: false
        // icon: require("../assets/icons/check-solid.svg"),
      },
      {
        value: 4,
        name: ` キャンセル院都合`,
        selected: false
        // icon: require("../assets/icons/check-solid.svg"),
      }
    ];

    const applyChanges = () => {
      if (selectedStatus.value) {
        const taskIds = selected.value
          .filter((task: any) => task.checked)
          .map((task: any) => {
            return task.id;
          });
        emit('updateStatus', { taskIds, status: selectedStatus.value });
      }
    };

    const check = () => {
      selected.value.forEach((reservation: any) => {
        reservation.checked = selectTask.value;
      });
    };

    const checkTask = (reservation: any) => {
      selectTask.value = false;
    };
    const filterReservationByPractitioner = (practitioner: any) => {
      emit('filterReservationByPractitioner', practitioner);
    };

    watch(
      () => props?.customerListData,
      function () {
        if (props?.customerListData.length > 0) {
          fetch(`api/v1/storeEmployee`)
            .then((res) => {
              const practionerRes = res.data.data;
              employeeData.value = practionerRes;
              return practionerRes;
            })
            .then((employeeData: any) => {
              let allEmployeeData: any = [];
              employeeData.map((employeeDatas: any) => {
                employeeDatas?.employeeInfo.map((employeeInfos: any) => {
                  allEmployeeData.push({ employeeInfos });
                });
              });
              let practitionerList: any = [];
              selected.value.map((practioner: any) => {
                if (practioner?.practitioner) {
                  practitionerList.push({
                    value: practioner?.practitioner?.id,
                    name: practioner?.practitioner?.name,
                    icon: tickIcon
                  });
                }
              });
              practitionerList.push({
                name: 'ALL',
                value: 'ALL',
                icon: tickIcon
              });
              const ids = practitionerList.map((o: any) => o.value);
              const filtered = practitionerList.filter(
                ({ value }: any, index: any) => !ids.includes(value, index + 1)
              );
              practitionerOptions.value = filtered;
            });
        } else {
          selectedPractitioner.value = '';
          practitionerOptions.value = [
            {
              name: 'ALL',
              value: 'ALL',
              icon: tickIcon
            }
          ];
          selectedPractitioner.value = 'ALL';
        }
      },
      {}
    );
    return {
      convertToStartingHH,
      filterReservationByPractitioner,
      selectedPractitioner,
      selectTask,
      checkTask,
      check,
      selectedStatus,
      applyChanges,
      goToMedical,
      reservationStatus,
      RESERVATION_STATUS,
      convertToHH,
      convertToMMDD,
      onSort,
      selectedSort,
      selected,
      sort,
      cancellationOptions,
      practitionerOptions,
      storeOptions: [
        {
          value: '（状態を選択）',
          name: '（状態を選択）',
          selected: true,
          icon: check
        },
        {
          value: '久我の杜院',
          name: '久我の杜院',
          selected: false,
          icon: check
        },
        {
          value: '伏見桃山院',
          name: '伏見桃山院',
          selected: false,
          icon: check
        }
      ],
      storeOptionsB: [
        {
          value: 'ALL',
          name: 'ALL',
          selected: true,
          icon: check
        },
        {
          value: '久我の杜院',
          name: '久我の杜院',
          selected: false,
          icon: check
        },
        {
          value: '伏見桃山院',
          name: '伏見桃山院',
          selected: false,
          icon: check
        }
      ]
    };
  }
});
