
import { defineComponent, ref, watchEffect, computed, onMounted } from 'vue';
import { Customer } from '../../core/interface';
import { store } from '@/core/store';
import moment from 'moment';
import { CONSULTATION_CLASSIFICATION, GENDER_TYPE } from '@/core/store/enums';
import { fetchPostalCode } from '@/core/api/postal-code-service';
import { useI18n } from 'vue-i18n';
import { toFullwidthKana } from 'japanese-string-utils';

export default defineComponent({
  name: 'Step1',
  // components: {
  //   Error,
  // },
  emits: ['showNext', 'customer'],
  props: {
    nextPage: {
      required: true,
      type: Number
    },
    consultationClassification: {
      require: true,
      type: Number
    }
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const {
      getters: { registrationSteps }
    } = store;
    const customerData = ref(registrationSteps.customerRegistration || undefined);
    const katakanaName = ref('');
    const katakanaSurname = ref('');
    const chineseLastName = ref('');
    const chineseFirstName = ref('');
    const dateOfBirth = ref('');
    const gender = ref(1);
    const postCode = ref('');
    const cityAddress = ref('');
    const nameOfBuildingsEtc = ref('');
    const cellNumber = ref('');
    const homeNumber = ref('');
    const profession = ref('');
    const dobYear = ref('');
    const dobMonth = ref('');
    const dobDay = ref('');
    let errors: any = ref([]);
    let yearData = ref<any>('');
    const prefectures = ref('');
    const consultationType = ref<any>(null);

    onMounted(() => {
      consultationType.value = props.consultationClassification;
    });

    const YEARS = () => {
      // const years = [];
      // const dateStart = moment(1900 - 1 - 1);
      // const dateEnd = moment(1900 - 1 - 1).add(100, "y");
      // while (dateEnd.diff(dateStart, "years") >= 0) {

      //   years.push({
      //     value: dateStart.format("YYYY"),
      //     name: dateStart.format("YYYY"),
      //   });
      //   dateStart.add(1, "year");
      // }
      // yearData.value = years;
      // return years;

      const numYears = 102;
      const years: any = [];
      const start = moment();
      const months = Array.from({ length: numYears }, (_, i) =>
        moment(start.subtract(1, 'year')).format('YYYY')
      ).reverse();
      if (months.length > 0) {
        const monthsData = months.forEach((dates: any) => {
          years.push({
            value: dates,
            name: dates
          });
        });
        yearData.value = years;
      }
    };

    const daysOption = computed(() => {
      let daysCalculated;
      if (dobMonth.value && Number(dobMonth.value) !== 0 && dobYear.value) {
        const numberOfDays =
          dobMonth.value && dobYear.value
            ? moment(`${dobYear?.value}-${dobMonth?.value}`, 'YYYY-MM')?.daysInMonth()
            : '';
        let daysArray: any =
          numberOfDays &&
          Array.from({ length: numberOfDays }, (v, i) => {
            return {
              value: i < 9 ? ('0' + Number(i + 1)).toString() : Number(i + 1).toString(),
              name: i < 9 ? ('0' + Number(i + 1)).toString() : Number(i + 1).toString(),
              selected: false,
              icon: require('@/assets/icons/check-solid.svg')
            };
          });

        daysCalculated = (daysArray && daysArray.length) > 0 ? daysArray : [];
        return daysCalculated;
      } else {
        daysCalculated = '-';
      }
      return daysCalculated;
    });

    const filterErrors = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x: { title: any }) => x.title === fieldName);
        return errorMessage[0]?.message;
      }
    };

    const handleNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    };

    const handlePostalcodeRegex = (event: any) => {
      const postCode = event?.target.value;
      var numberPattern = /^[0-9]+$/g;
      const test = numberPattern.test(postCode);
      if (test) {
        //   errors.value.push({
        //     title: "postCode",
        //     message: i18n.t("Errors.E063"),
        //   });
        // } else {
        if (postCode.length > 7) {
          errors.value.push({
            title: 'postCode',
            message: i18n.t('Errors.E060')
          });
        } else if (postCode.length < 7) {
          errors.value.push({
            title: 'postCode',
            message: i18n.t('Errors.E063')
          });
        }
      }
    };

    const handleNumberRegex = (evt: any, type: any) => {
      const getValue = evt?.target.value;
      var numberPattern = /^[0-9]+$/g;

      const test = numberPattern.test(getValue);

      if (type === 'postCode') {
        if (test === true) {
          postCode.value = getValue;
        } else {
          postCode.value = '';
        }
      } else if (type === 'cellNumber') {
        if (test === true) {
          cellNumber.value = getValue;
        } else {
          cellNumber.value = '';
        }
      } else if (type === 'homeNumber') {
        if (test === true) {
          homeNumber.value = getValue;
        } else {
          homeNumber.value = '';
        }
      }
    };

    const filterErrorColor = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x: { title: any }) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    };
    const validateBlankSpace = (event: any, fieldType: any) => {
      if (event && fieldType == 'katakanaSurname') {
        katakanaSurname.value = event.target.value.trim();
        katakanaSurname.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'katakanaName') {
        katakanaName.value = event.target.value.trim();
        katakanaName.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'chineseLastName') {
        chineseLastName.value = event.target.value.trim();
        chineseLastName.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'chineseFirstName') {
        chineseFirstName.value = event.target.value.trim();
        chineseFirstName.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'profession') {
        profession.value = event.target.value.trim();
        profession.value = toFullwidthKana(`${event.target.value.trim()}`);
      }

      if (event && fieldType == 'buildingName') {
        nameOfBuildingsEtc.value = event.target.value.trim();
        nameOfBuildingsEtc.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'prefectures') {
        prefectures.value = event.target.value.trim();
        prefectures.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'cityAddress') {
        cityAddress.value = event.target.value.trim();
        cityAddress.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
    };

    const onKeyPress = (evt: any) => {
      const str = evt.target.value;
      const re = /^[ァ-ヾ]*/;
      const found = str.match(re);
      var firstItem = Array.isArray(found) ? found.shift() : [];
      if (!firstItem) {
        evt.preventDefault();
      }
    };

    const onKanaChange = (evt: any, type: any) => {
      const getValue = evt?.target.value;
      var numberPattern = /^[ァ-ヾ]*/;

      if (type === 'katakanaSurname') {
        const found = getValue.match(numberPattern);
        var firstItem = Array.isArray(found) ? found.shift() : [];
        if (!firstItem) {
          katakanaSurname.value = '';
        } else {
          katakanaSurname.value = getValue;
        }
      }
    };

    YEARS();
    watchEffect(() => {
      (prefectures.value = customerData?.value?.prefectures),
        (katakanaName.value = customerData?.value?.katakana_name),
        (katakanaSurname.value = customerData?.value?.kana_surname),
        (chineseLastName.value = customerData?.value?.surname),
        (chineseFirstName.value = customerData?.value?.name),
        (gender.value = customerData?.value?.gender),
        (postCode.value = customerData?.value?.postal_code),
        (cityAddress.value = customerData?.value?.city),
        (nameOfBuildingsEtc.value =
          customerData?.value?.name_of_buildings === 'undefined'
            ? ''
            : customerData?.value?.name_of_buildings),
        (cellNumber.value = customerData?.value?.cellphone_number),
        (homeNumber.value = customerData?.value?.telephone_number),
        (profession.value =
          customerData?.value?.profession === 'undefined' ? '' : customerData?.value?.profession),
        (dobYear.value = customerData?.value?.dob
          ? customerData?.value?.dob.split('-')[0]
          : '1980'),
        (dobMonth.value = customerData?.value?.dob ? customerData?.value?.dob.split('-')[1] : '0'),
        (dobDay.value = customerData?.value?.dob ? customerData?.value?.dob.split('-')[2] : '0');
    });

    const calAge = (date: any) => {
      var diff_ms = Date.now() - date.getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    };

    const checkPostalCodeValid = (value: string) => {
      if (value === '') {
        removeError('postCode');
      }
    };

    const removeError = (errorTitle: string) => {
      let indexOfValue = -1;
      errors.value?.some((error: { title: string }, index: number) => {
        if (error.title === errorTitle) {
          indexOfValue = index;
        }
        return error.title === errorTitle;
      });
      if (indexOfValue > -1) {
        errors.value.splice(indexOfValue, 1);
      }
    };

    const searchPostalCode = () => {
      removeError('postCode');
      let indexOfPostalCode = -1;
      errors.value?.some((error: { title: string }, index: number) => {
        if (error.title === 'postCode') {
          indexOfPostalCode = index;
        }
        return error.title === 'postCode';
      });
      if (indexOfPostalCode > -1) {
        errors.value.splice(indexOfPostalCode, 1);
      }
      fetchPostalCode(postCode.value)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data && res.data.data.length > 0) {
              const postalCodeData = res.data.data[0];
              prefectures.value = postalCodeData.prefecture;
              // Set city
              let city = postalCodeData.city;
              if (postalCodeData.town) {
                city += ' ' + postalCodeData.town;
              }
              if (postalCodeData.koaza) {
                city += ' ' + postalCodeData.koaza;
              }
              if (postalCodeData.kyoto_street) {
                city += ' ' + postalCodeData.kyoto_street;
              }
              cityAddress.value = city;
              // Set Building
              let building = postalCodeData.building;
              if (postalCodeData.floor) {
                building += ' ' + postalCodeData.floor;
              }
              nameOfBuildingsEtc.value = building;
            } else {
              errors.value.push({
                title: 'postCode',
                message: i18n.t('Ticket.Step1.InvalidPostalCode')
              });
            }
          }
        })
        .catch((err) => {
          errors.value.push({
            title: 'postCode',
            message: i18n.t('Ticket.Step1.InvalidPostalCode')
          });
        });
    };

    const showNext = async () => {
      errors.value = [];
      let customer = {
        katakana_name: katakanaName.value,
        kana_surname: katakanaSurname.value,
        name: chineseFirstName.value,
        surname: chineseLastName.value,
        gender: gender.value === undefined ? 1 : gender.value,
        dob: `${dobYear.value}-${dobMonth.value}-${dobDay.value}`,
        postal_code: postCode.value,
        city: cityAddress.value,
        prefectures: prefectures.value,
        name_of_buildings: nameOfBuildingsEtc.value,
        cellphone_number: cellNumber.value === undefined ? '' : cellNumber.value,
        telephone_number: homeNumber.value === undefined ? '' : homeNumber.value,
        profession: profession.value,
        age: calAge(new Date(Number(dobYear.value), Number(dobMonth.value), Number(dobDay.value)))
      };

      if (customer.postal_code) {
        if (Number(customer.postal_code.length) !== 7) {
          errors.value.push({
            title: 'postCode',
            message: i18n.t('Errors.E063')
          });
        }
      }

      if (customer.dob) {
        let dobYear = customer.dob.split('-')[0];
        let dobMonth = customer.dob.split('-')[1];
        let dobDay = customer.dob.split('-')[2];
        if (dobYear === '0' || undefined) {
          errors.value.push({
            title: 'year',
            message: i18n.t('Errors.E061')
          });
        }
        if (dobMonth === '0' || undefined) {
          errors.value.push({
            title: 'month',
            message: i18n.t('Errors.E061')
          });
        }
        if (dobDay === '0' || undefined) {
          errors.value.push({
            title: 'day',
            message: i18n.t('Errors.E061')
          });
        }
      }
      if (!customer.prefectures) {
        errors.value.push({
          title: 'prefectures',
          message: i18n.t('Errors.E061')
        });
      }
      if (customer.prefectures && customer.prefectures.length > 255) {
        errors.value.push({
          title: 'prefectures',
          message: i18n.t('Errors.E060')
        });
      }
      if (!customer.katakana_name) {
        errors.value.push({
          title: 'katakanaName',
          message: i18n.t('Errors.E061')
        });
      }

      if (customer.katakana_name) {
        const getValue1 = customer.katakana_name;
        var numberPattern2 = /^[ァ-ヾ]*$/g;

        const found = getValue1.match(numberPattern2);
        var firstItem2 = Array.isArray(found) ? found.shift() : [];
        if (!firstItem2 || firstItem2.length === 0) {
          errors.value.push({
            title: 'katakanaName',
            message: i18n.t('Errors.E063')
          });
        }
        if (firstItem2 && customer.katakana_name.length > 82) {
          errors.value.push({
            title: 'katakanaName',
            message: i18n.t('Errors.E060')
          });
        }
      }

      if (!customer.kana_surname) {
        errors.value.push({
          title: 'katakanaSurname',
          message: i18n.t('Errors.E061')
        });
      }

      if (customer.kana_surname) {
        const getValue = customer.kana_surname;
        var numberPattern = /^[ァ-ヾ]*$/g;
        const found = getValue.match(numberPattern);
        var firstItem = Array.isArray(found) ? found.shift() : [];
        if (!firstItem || firstItem.length === 0) {
          errors.value.push({
            title: 'katakanaSurname',
            message: i18n.t('Errors.E063')
          });
        }
        if (firstItem && customer.kana_surname.length > 82) {
          errors.value.push({
            title: 'katakanaSurname',
            message: i18n.t('Errors.E060')
          });
        }
      }

      if (!customer.name) {
        errors.value.push({
          title: 'chineseFirstName',
          message: i18n.t('Errors.E061')
        });
      }
      if (customer.name) {
        if (customer.name && customer.name.length > 85) {
          errors.value.push({
            title: 'chineseFirstName',
            message: i18n.t('Errors.E060')
          });
        }
      }
      if (!customer.surname) {
        errors.value.push({
          title: 'surname',
          message: i18n.t('Errors.E061')
        });
      }
      if (customer.surname) {
        if (customer.surname && customer.surname.length > 85) {
          errors.value.push({
            title: 'surname',
            message: i18n.t('Errors.E060')
          });
        }
      }

      if (!customer.city) {
        // errors.value = "は必須です";
        errors.value.push({
          title: 'city',
          message: i18n.t('Errors.E061')
        });
      }

      if (!customer.cellphone_number && !customer.telephone_number) {
        errors.value.push({
          title: 'cellphone_number',
          message: i18n.t('Errors.E061')
        });
        //telphoneNumber
        errors.value.push({
          title: 'homeNumber',
          message: i18n.t('Errors.E061')
        });
      }
      // if (!customer.telephone_number ) {
      //   errors.value.push({
      //     title: "homeNumber",
      //     message: i18n.t("Errors.E061"),
      //   });
      // }

      if (customer.telephone_number) {
        const getValue1 = customer.telephone_number;
        var numberPattern1 = /^[0-9]+$/g;

        const test = numberPattern1.test(getValue1);
        if (test === true) {
          const cellphoneNumber0 = getValue1.toString().charAt(0) === '0' ? true : false;
          if (cellphoneNumber0 === true) {
            switch (cellphoneNumber0 === true) {
              case cellphoneNumber0 === true && getValue1.length === 10:
                break;
              case cellphoneNumber0 === true && getValue1.length === 11:
                break;
              default:
                errors.value.push({
                  title: 'telephone_number',
                  message: i18n.t('Errors.E060')
                });
            }
          }
          if (
            customer.telephone_number &&
            customer.telephone_number.length > 11
          ) {
            errors.value.push({
              title: "homeNumber",
              message: i18n.t("Errors.E060"),
            });
          }
          const getFirstCharacterInNumberTelephone = customer.telephone_number;
          const getFirstCharacterInCellNumber = customer.cellphone_number;
          // if (getFirstCharacterInCellNumber.toString().charAt(0) !== "0") {
          //   errors.value.push({
          //     title: "homeNumber",
          //     message: i18n.t("Errors.E063"),
          //   });
          // }
          if (getFirstCharacterInNumberTelephone.toString().charAt(0) !== '0') {
            errors.value.push({
              title: 'homeNumber',
              message: `形式違い`
            });
          }
        } else {
          errors.value.push({
            title: 'homeNumber',
            message: i18n.t('Errors.E063')
          });
        }

        // };

        // const getFirstCharacterInNumberTelephone = customer.telephone_number;
        // if (getFirstCharacterInNumberTelephone.toString().charAt(0) !== "0") {
        //   errors.value.push({
        //     title: "homeNumber",
        //     message: i18n.t("Errors.E063"),
        //   });
        // }
        // if (
        //   getFirstCharacterInNumberTelephone.toString().charAt(0) !== "0" &&
        //   getFirstCharacterInNumberTelephone.length < 10
        // ) {
        //   errors.value.push({
        //     title: "telephone_number",
        //     message: `形式違い`,
        //   });
        // }
      }
      if (customer.cellphone_number) {
        const getValueCellPhoneNumber = customer.cellphone_number;
        var numberPattern4 = /^[0-9]+$/g;

        const test2 = numberPattern4.test(getValueCellPhoneNumber);
        if (test2 === true) {
          const cellphoneNumber0 =
            getValueCellPhoneNumber.toString().charAt(0) === '0' ? true : false;
          if (cellphoneNumber0 === true) {
            switch (cellphoneNumber0 === true) {
              case cellphoneNumber0 === true && getValueCellPhoneNumber.length === 10:
                break;
              case cellphoneNumber0 === true && getValueCellPhoneNumber.length === 11:
                break;
              default:
                errors.value.push({
                  title: 'telephone_number',
                  message: i18n.t('Errors.E060')
                });
            }
          }
          if (
            customer.cellphone_number &&
            customer.cellphone_number.length > 11
          ) {
            errors.value.push({
              title: "cellphone_number",
              message: i18n.t("Errors.E060"),
            });
          }
          const getFirstCharacterInNumberTelephone = customer.telephone_number;
          const getFirstCharacterInCellNumber = customer.cellphone_number;
          if (getFirstCharacterInCellNumber.toString().charAt(0) !== '0') {
            errors.value.push({
              title: 'cellphone_number',
              message: i18n.t('Errors.E063')
            });
          }
          // if (
          //     getFirstCharacterInNumberTelephone.toString().charAt(0) !== "0" &&
          //     getFirstCharacterInNumberTelephone.length < 10
          // ) {
          //   errors.value.push({
          //     title: "telephone_number",
          //     message: `形式違い`,
          //   });
          // }
        } else {
          errors.value.push({
            title: 'cellphone_number',
            message: i18n.t('Errors.E063')
          });
        }

        // const getFirstCharacterInNumber = customer.cellphone_number;
        // if (getFirstCharacterInNumber.toString().charAt(0) !== "0") {
        //   errors.value.push({
        //     title: "cellphone_number",
        //     message: i18n.t("Errors.E063"),
        //   });
        // }
        // if (
        //   getFirstCharacterInNumber.toString().charAt(0) !== "0" &&
        //   getFirstCharacterInNumber.length < 10
        // ) {
        //   errors.value.push({
        //     title: "telephone_number",
        //     message: `形式違い`,
        //   });
        // }
      }
      if (
        customer.katakana_name &&
        customer.kana_surname &&
        customer.name &&
        customer.surname &&
        customer.prefectures &&
        customer.city &&
        (customer.dob.split('-')[0] !== '0' || undefined) &&
        (customer.dob.split('-')[1] !== '0' || undefined) &&
        (customer.dob.split('-')[2] !== '0' || undefined) &&
        errors.value.length === 0
      ) {
        errors.value = '';
        emit('showNext', props.nextPage);
        emit('customer', customer);
      }
    };

    return {
      handleNumberRegex,
      onKeyPress,
      validateBlankSpace,
      handleNumber,
      filterErrors,
      consultationType,
      CONSULTATION_CLASSIFICATION,
      GENDER_TYPE,
      dobYear,
      dobMonth,
      dobDay,
      gender,
      postCode,
      cityAddress,
      nameOfBuildingsEtc,
      cellNumber,
      homeNumber,
      profession,
      dateOfBirth,
      chineseLastName,
      katakanaName,
      chineseFirstName,
      katakanaSurname,
      prefectures,
      yearOptions: yearData,
      monthOptions: [
        {
          value: '01',
          name: '01',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '02',
          name: '02',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '03',
          name: '03',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '04',
          name: '04',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '05',
          name: '05',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '06',
          name: '06',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '07',
          name: '07',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '08',
          name: '08',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '09',
          name: '09',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '10',
          name: '10',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '11',
          name: '11',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        },
        {
          value: '12',
          name: '12',
          selected: false,
          icon: require('../../assets/icons/check-solid.svg')
        }
      ],
      daysOption,
      // dayOptions: [
      //   {
      //     value: "01",
      //     name: "01",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "02",
      //     name: "02",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "03",
      //     name: "03",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "04",
      //     name: "04",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "05",
      //     name: "05",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "06",
      //     name: "06",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "07",
      //     name: "07",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "08",
      //     name: "08",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "09",
      //     name: "09",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "10",
      //     name: "10",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "11",
      //     name: "11",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "12",
      //     name: "12",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "13",
      //     name: "13",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "14",
      //     name: "14",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "15",
      //     name: "15",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "16",
      //     name: "16",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "17",
      //     name: "17",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "18",
      //     name: "18",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "19",
      //     name: "19",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "20",
      //     name: "20",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "21",
      //     name: "21",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "22",
      //     name: "22",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "23",
      //     name: "23",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "24",
      //     name: "24",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "25",
      //     name: "25",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "26",
      //     name: "26",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "27",
      //     name: "27",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "28",
      //     name: "28",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "29",
      //     name: "29",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "30",
      //     name: "30",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      //   {
      //     value: "31",
      //     name: "31",
      //     selected: false,
      //     icon: require("../../assets/icons/check-solid.svg"),
      //   },
      // ],
      errors,
      filterErrorColor,
      //
      searchPostalCode,
      showNext,
      checkPostalCodeValid,
      handlePostalcodeRegex,
      removeError
    };
  }
});
