
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { store, useStore } from '@/core/store';
import ReservationDetailCard from '../../components/ReservationDetailCard.vue';
// import ReservationFirstVisit from "../components/ReservationFirstVisit.vue";
// import ReservationContinuous from "../components/ReservationContinuous.vue";
import ReservationReview from '../../components/reservation-detail-tabs/ReservationReview.vue';
import PreliminaryExamination from '../../components/reservation-detail-tabs/PreliminaryExamination.vue';
import Inquire from '../../components/reservation-detail-tabs/Inquire.vue';
import PolicyForm from '../../components/reservation-detail-tabs/PolicyForm.vue';
// import Complaints from "../components/Complaints.vue";
import Treatment from '../../components/reservation-detail-tabs/Treatment.vue';
import Personal from '../../components/reservation-detail-tabs/Personal.vue';
import ReservationCancleModal from '../../components/modals/ReservationCancleModal.vue';
import BackConfirmationModal from '../../components/modals/BackConfirmationModal.vue';
import axios from 'axios';
import moment from 'moment';
import { store as post } from '@/core/api/api-service';
import { RESERVATION_STATUS, CONSULTATION_CLASSIFICATION } from '@/core/store/enums';
import { createTemoraryTransaction } from '@/core/api/transaction-service';
import { Customer } from '@/core/interface';
import { Modal as BootstrapModal } from 'bootstrap';
import Loading from 'vue-loading-overlay';
import i18n from '../../i18n';
import { getFutureReservations } from '@/core/api/accounting-service';
import AlertModal from '@/components/modals/AlertModal.vue'
import * as bootstrap from 'bootstrap';
import { useToast, POSITION } from 'vue-toastification';

export default defineComponent({
  name: 'ReservationDetail',
  components: {
    AlertModal,
    Loading,
    ReservationDetailCard,
    ReservationReview,
    PreliminaryExamination,
    Inquire,
    Treatment,
    Personal,
    PolicyForm,
    ReservationCancleModal,
    BackConfirmationModal
  },
  data() {
    return {
      RESERVATION_STATUS: RESERVATION_STATUS,
      CONSULTATION_CLASSIFICATION: CONSULTATION_CLASSIFICATION,
      selectedTab: 'reserve'
    };
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const reservation = ref<any>();
    const medicalRecord = ref(null);
    const previousReservation = ref<any>();
    const previousMedicalRecord = ref<any>();
    const activeRegistrationButton = ref(false);
    const reservationId = route.params.id;
    const personalInfo = ref(null);
    const hasCustomer = ref(false);
    const editButtonDisable = ref(false);
    const apiLoading = ref(false);
    const isCurrentOrPastDay = ref(false);
    const toast = useToast();
    const isToday = ref(false);

    onMounted(async () => {
      await store.dispatch('resetRegistrationSteps', {});
      await getReservation();
      isToday.value = moment(reservation.value.date).isSame(moment(), 'day');
    });

    const updateReservation = (shouldUpdate: boolean) => {
      if (shouldUpdate) {
        getReservation();
      }
    };

    const getReservation = async () => {
      apiLoading.value = true;
      const reservationResponse = await axios(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${reservationId}`,
        {
          headers: { Authorization: 'Bearer ' + store.getters.token }
        }
      );
      if (reservationResponse.status === 200) {
        const reservationRes = reservationResponse.data.data;
        reservation.value = reservationRes;
        reservation.value?.customer?.customerNumber
          ? (hasCustomer.value = true)
          : (hasCustomer.value = false);
        reservation.value = reservationRes;
        if (
          (reservation.value.consultationClassification ===
            CONSULTATION_CLASSIFICATION.FIRST_VISIT ||
            reservation.value.consultationClassification ===
              CONSULTATION_CLASSIFICATION.RE_EXAMINATION) &&
          !reservationRes?.medicalRecord
        ) {
          const medicalRecordResponse = await axios.post(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records`,
            {
              customer_number: reservation.value?.customer?.customerNumber
                ? reservation.value?.customer?.customerNumber
                : null,
              reservation_id: reservationRes.id,
              consultation_classification: reservationRes.consultationClassification,
              date: moment().format('YYYY-MM-DD')
            },
            {
              headers: { Authorization: 'Bearer ' + store.getters.token }
            }
          );
          if (medicalRecordResponse.status === 200) {
            const medicalResData = medicalRecordResponse.data.data;
            reservationRes.medicalRecord = medicalResData;
            medicalRecord.value = medicalResData;

            reservation.value = reservationRes;
          }
        } else if (reservationRes?.medicalRecord) {
          const medicalRecordResponse = await axios(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${reservationRes?.medicalRecord.id}`,
            {
              headers: { Authorization: 'Bearer ' + store.getters.token }
            }
          );

          if (medicalRecordResponse.status === 200) {
            setMedicalRecordData(medicalRecordResponse.data.data);
          }
        }

        if (
          reservationRes.consultationClassification ===
            CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT ||
          reservationRes.consultationClassification === CONSULTATION_CLASSIFICATION.RE_EXAMINATION
        ) {
          const reservationResData = await axios(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/previous`,
            {
              headers: { Authorization: 'Bearer ' + store.getters.token },
              params: {
                reservationId: reservationRes?.id,
                customerNumber: reservationRes?.customer?.customerNumber
              }
            }
          );
          if (reservationResData.status === 200) {
            if (reservationResData.data?.data) {
              previousReservation.value = reservationResData.data?.data;
              if (
                reservationRes.consultationClassification ===
                CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
              ) {
                const medicalRecordResponse = await axios(
                  `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${previousReservation.value?.medicalRecord?.id}`,
                  {
                    headers: { Authorization: 'Bearer ' + store.getters.token }
                  }
                );
                if (medicalRecordResponse.status === 200) {
                  previousMedicalRecord.value = medicalRecordResponse.data.data;
                  if (reservation.value?.medicalRecord === null) {
                    let formDataForMedicalContinueUpdate: any = {};
                    formDataForMedicalContinueUpdate.medical_record_id =
                      previousReservation.value?.medicalRecord?.id;
                    formDataForMedicalContinueUpdate._method = 'PUT';
                    post(
                      `/api/v1/reservations/update-medical-record/${reservation.value?.id}`,
                      formDataForMedicalContinueUpdate
                    ).then((res) => {
                      if (res.status === 200) {
                        getReservation();
                      }
                    });
                  }
                }
              }
            }
          }
        }
        isCurrentOrPastDay.value = moment(reservation.value.date).isSameOrBefore(moment(), 'day');
        apiLoading.value = false;
      }
      apiLoading.value = false;
    };

    const setMedicalRecordData = async (medicalRecordData: any) => {
      if (medicalRecordData) {
        medicalRecord.value = medicalRecordData;
      }

      if (medicalRecordData?.customer) {
        let responseDataForCustomer = medicalRecordData?.customer;

        let customer = {
          katakana_name: responseDataForCustomer?.katakanaName,
          kana_surname: responseDataForCustomer?.kanaSurname,
          name: responseDataForCustomer?.name,
          surname: responseDataForCustomer?.surname,
          gender: responseDataForCustomer?.gender,
          dob: responseDataForCustomer?.dob,
          postal_code: responseDataForCustomer?.postalCode,
          city: responseDataForCustomer?.city,
          prefectures: responseDataForCustomer.prefectures,
          name_of_buildings: responseDataForCustomer?.nameofBuilding,
          cellphone_number: responseDataForCustomer?.cellphoneNumber,
          telephone_number: responseDataForCustomer?.telephoneNumber,
          profession: responseDataForCustomer?.profession,
          age: responseDataForCustomer?.age
        };
        store.dispatch('setStepCustomerRegistration', customer).then(() => {
          // customerData = customer;
        });
      }

      // else if (!medicalRecordResponse?.data?.data?.customer) {
      //   await store.dispatch("resetRegistrationSteps", {});
      // }
      if (medicalRecordData?.preliminaryExamination) {
        let symptonQuestions = medicalRecordData?.preliminaryExamination?.symptom_answer;
        store.dispatch('setStepSymptomQuestions', symptonQuestions).then(() => {
          // customerData = customer;
        });
      }
      // else if (
      //   !medicalRecordResponse?.data?.data?.preliminaryExamination
      // ) {
      //   await store.dispatch("resetRegistrationSteps", {});
      // }

      if (medicalRecordData?.questionnaire) {
        let questionaires = medicalRecordData?.questionnaire?.questionAnswer;
        await store.dispatch('setStepReviewQuestions', JSON.parse(questionaires));
      }
      // else if (!medicalRecordResponse?.data?.data?.questionnaire) {
      //   await store.dispatch("resetRegistrationSteps", {});
      // }
      if (medicalRecordData?.questionnaire) {
        let questionaires = medicalRecordData?.questionnaire?.questionAnswer;
        await store.dispatch('setStepReviewQuestions', JSON.parse(questionaires));
      }
      // else if (!medicalRecordResponse?.data?.data?.questionnaire) {
      //   await store.dispatch("resetRegistrationSteps", {});
      // }

      const customerNumber = medicalRecordData?.customer?.customerNumber;
      const reservationIdForPersonal = medicalRecordData?.reservation?.id;
      if (customerNumber && reservationIdForPersonal) {
        const personalResponse = await axios(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/personals?customerNumber=${customerNumber}&reservationId=${reservationIdForPersonal}`,
          {
            headers: { Authorization: 'Bearer ' + store.getters.token }
          }
        );
        if (personalResponse.status === 200) {
          const personalResData = personalResponse.data.data;
          await store.dispatch('setStepPersonalInfo', personalResData?.[0]?.info);
        } else if (personalResponse.data.data?.[0]?.info) {
          await store.dispatch('resetRegistrationSteps', {});
        }
      } else if (!customerNumber || !reservationIdForPersonal) {
        await store.dispatch('resetRegistrationSteps', {});
        const customerNumber = medicalRecordData?.customer?.customerNumber;
        const reservationIdForPersonal = medicalRecordData?.reservation?.id;
        if (customerNumber && reservationIdForPersonal) {
          const personalResponse = await axios(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/personals?customerNumber=${customerNumber}&reservationId=${reservationIdForPersonal}`,
            {
              headers: { Authorization: 'Bearer ' + store.getters.token }
            }
          );
          if (personalResponse.status === 200) {
            const personalResData = personalResponse.data.data;
            await store.dispatch('setStepPersonalInfo', personalResData?.[0]?.info);
          } else if (personalResponse.data.data?.[0]?.info) {
            await store.dispatch('resetRegistrationSteps', {});
          }
        } else if (!customerNumber || !reservationIdForPersonal) {
          await store.dispatch('resetRegistrationSteps', {});
        }
      }
      setUpActiveRegistrationButtonOnCurrentTime();
    };

    const goToReservationRegistration = () => {
      const reservationData: any = reservation.value;
      router.push({
        name: 'ReservationRegistration',
        params: {
          hasMedicalRecord: reservation.value?.medicalRecord?.id ? 'true' : 'false',
          isFromEdit: 'true',
          redirect: 'ReservationDetail',
          id: reservationData?.id || '',
          date: reservationData?.date || '',
          startHour: moment(reservationData?.startingTime, 'HH:mm:ss').format('HH:mm') || '',
          endHour: moment(reservationData?.endingTime, 'HH:mm:ss').format('HH:mm') || '',
          store: reservationData?.store?.id || '',
          practitioner: reservationData?.practitioner?.id || '',
          type: reservationData?.reservationType,
          reservation_status: reservationData?.reservationStatus,
          reservation_person: reservationData.reservationPerson,
          phone: reservationData.phone,
          consultation_classification: reservationData.consultationClassification,
          treatment_menu_id: reservationData.treatmentMenu?.id,
          symptom: reservationData.symptom,
          management_memo: reservationData.managementMemo,
          same_person_confirmation: reservationData.samePersonConfirmed ? 1 : 0,
          reservationRoute: reservationData.reservationRoute || ''
        }
      });
    };

    const setUpActiveRegistrationButtonOnCurrentTime = () => {
      const reservationData: any = reservation.value;
      activeRegistrationButton.value = moment(
        `${reservationData?.date}T${reservationData?.startingTime}`,
        'YYYY-MM-DDTHH:mm:ss'
      ).isAfter(moment());
    };

    const ifMedicalDataIsPresent = () => {
      const reservationData: any = reservation.value;
      var disabled;
      let dateIsPassed = moment(`${reservationData?.date}`, 'YYYY-MM-DD').isSameOrAfter(
        moment(`${moment().format('YYYY-MM-DD')}`, 'YYYY-MM-DD')
      );
      if (dateIsPassed) {
        if (
          (medicalRecord.value ||
            medicalRecord.value === null ||
            previousMedicalRecord.value === undefined ||
            previousMedicalRecord.value) &&
          reservationData.consultationClassification !== 1
        ) {
          editButtonDisable.value = true;
          disabled = true;
          return disabled;
        } else {
          editButtonDisable.value = false;
          disabled = false;
          return disabled;
        }
      } else {
        editButtonDisable.value = true;
        disabled = true;
        return disabled;
      }
    };

    watch(
      () => reservation.value || medicalRecord.value || previousMedicalRecord.value,
      function () {
        ifMedicalDataIsPresent();
        setUpActiveRegistrationButtonOnCurrentTime();
      },
      {}
    );

    const onReservationCancelModal = () => {
      const element = document.getElementById('reservationCancleModal') as HTMLElement;
      const myModal = new BootstrapModal(element);
      myModal.show();
    };
    const cancelReservation = ($event: number) => {
      const reservationData: any = reservation.value;
      const url =
        reservationData?.reservationType === 1
          ? `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${reservation.value?.id}`
          : reservationData?.reservationType == 2
          ? `${process.env.VUE_APP_BASE_API_URL}/api/v1/tasks/${reservation.value?.id}`
          : `${process.env.VUE_APP_BASE_API_URL}/api/v1/public-holidays/${reservation.value?.id}`;
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('date', reservation.value?.date);
      formData.append(
        'starting_time',
        moment(reservation.value?.startingTime, 'HH:mm:ss').format('HH:mm')
      );
      formData.append(
        'ending_time',
        moment(reservation.value?.endingTime, 'HH:mm:ss').format('HH:mm')
      );
      formData.append('id', reservation.value?.id);
      formData.append('cancellation_type', $event.toString());
      formData.append('reservation_status', '6');
      formData.append('store_id', reservation.value?.store?.id);
      formData.append('practitioner_id', reservation.value?.practitioner?.id);
      return post(`${url}`, formData).then((res) => {
        if (res.status === 200) {
          const calendarModalElement = document.getElementById(
            'reservationCancleModal'
          ) as HTMLElement;
          let calendarModal = BootstrapModal.getInstance(calendarModalElement);
          if (!calendarModal || calendarModal === null) {
            calendarModal = new BootstrapModal(calendarModalElement);
            calendarModal.hide();
          } else {
            calendarModal.hide();
          }
           toast.success('キャンセルしました。', {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
          router.push({ name: 'ReservationCalendar' });
        }
        const calendarModalElement = document.getElementById(
          'reservationCancleModal'
        ) as HTMLElement;
        let calendarModal = BootstrapModal.getInstance(calendarModalElement);
        if (!calendarModal || calendarModal === null) {
          calendarModal = new BootstrapModal(calendarModalElement);
          calendarModal.hide();
        } else {
          calendarModal.hide();
        }
      });
    };

    const nextTimeClick = () => {
      const toast = useToast();
      if(store.getters.user.currentAssignment.length == 0) {
        toast.error('こちらのスタッフは店舗配されておりません', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      if (!reservation.value?.customer?.customerNumber) {
        toast.error('顧客情報を登録してください', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      store.commit('setSecondBackButton', true);
      store.dispatch('setSecondBackId', reservationId);
      store.commit('setReservationCalendarStoreId', reservation.value?.store?.id);
      store.commit('setReservationCalendarStoreDate', moment(reservation.value?.date).add(1, 'days').format("YYYY-MM-DD"));

      router.push({
        name: 'ReservationCalendar',
        params: {
          customerNumber:
            reservation.value?.customerNumber || reservation.value?.customer?.customerNumber,
          name: reservation.value?.customer?.name,
          surname: reservation.value?.customer?.surname,
          telephoneNumber: reservation.value?.customer?.telephoneNumber,
          cellphoneNumber: reservation.value?.customer?.cellphoneNumber,
          reservationPerson: reservation.value?.reservationPerson,
          phone: reservation.value?.phone,
          practitionerId: reservation.value?.practitioner.id,
        }
      });
    };

    const openLastReservation = () => {
      apiLoading.value = true;
      axios(`${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/consults`, {
        headers: { Authorization: 'Bearer ' + store.getters.token },
        params: {
          reservationId: reservation.value.id,
          customerNumber: reservation.value.customer.customerNumber
        }
      })
        .then((res) => {
          const reservationRes = res.data.data;
          apiLoading.value = false;
          let routeData = router.resolve({
            name: 'ReservationDetail',
            params: {
              id: reservationRes.id
            }
          });
          window.open(routeData.href, '_blank');
        })
        .catch((err) => {
          console.log(err);
          apiLoading.value = false;
        });
    };

    const temporaryTransaction = () => {

      createTemoraryTransaction(
        reservation.value?.customer.customerNumber ||
        reservation.value?.customer?.customerNumber,
        // reservation.value?.store?.id,
        store.getters?.user?.currentAssignment?.[0]?.storeId, //sending storeId of current user
        'reservation',
        reservation.value?.id,
        reservation?.value.practitioner?.id
      )
        .then((res) => {
          apiLoading.value = false;
          // Added record to temporary sales = 仮売上高にレコードを追加
          toast.success('レジにデータ送信完了', {
            //Added record to temporary sales
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
          if (reservation.value.customer?.memberRemainingCoupon === 1) {
            toast.error(i18n.global.t('Errors.C103'), {
              //Added record to temporary sales
              timeout: 3000,
              position: POSITION.BOTTOM_RIGHT
            });
          }
          getReservation();
          // this.$emit("updateEvents");
          // this.$emit("setReservationId", this.reservation?.id);
        })
        .catch((err) => {
          console.log(err);
          apiLoading.value = false;
        });
    }

    const pay = () => {
      if (!reservation.value?.customer?.customerNumber) {
        toast.error(i18n.global.t('Errors.E102'), {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      if(store.getters.user.currentAssignment.length == 0) {
        toast.error('こちらのスタッフは店舗配属されておりません。', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }


      // If the next reservation date is undecided, Alert will be displayed.

      // Accounting cooperation is not possible if the reservation date is past the day
      // Today> The date of the selected reservation slot (V_calendar.date) is error E102

      if (
        moment(moment().format('YYYY-MM-DD')).isAfter(moment(reservation.value.date, 'YYYY-MM-DD'))  
        && reservation.value.reservationStatus !== RESERVATION_STATUS.RECEPTION 
      ) {
        // toast.error(i18n.global.t('Errors.E102'), {
        toast.error('予約日が当日を過ぎています', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      if (reservation?.value.customer?.customerNumber && reservation?.value.date) {
        getFutureReservations(reservation?.value.customer?.customerNumber, reservation?.value.date)
          .then((res) => {
            if (res.length === 0) {
              // toast.error(i18n.global.t('Errors.C100'), {
              //   timeout: 3000,
              //   position: POSITION.BOTTOM_RIGHT
              // });
              const alertModalElement = document.getElementById("alertModal") as HTMLElement;
              const alertModal = new BootstrapModal(alertModalElement);
              alertModal.show();
            } else {
              temporaryTransaction();
            }
            // const todaysDate = moment().format('YYYY-MM-DD');
            // let formData = new FormData();
            // formData.append('_method', 'PUT');
            // let reservationFormData = new FormData();
            // reservationFormData.append('_method', 'PUT');
            // reservationFormData.append('reservation_status', `${RESERVATION_STATUS.TOTAL}`);
            // // T_Reserved. If the first accounted date and time is NULL
            // if (!reservation.value.firstAccountedDatetime) {
            //   // V_Calendar. If the consultation category is revisit (3), set T_Customer.Number of hospital visits to 1 (reset). Other than that, T_customer. Increase the number of hospital visits by 1.
            //   if (
            //     reservation.value.consultationClassification ===
            //       CONSULTATION_CLASSIFICATION.FIRST_VISIT ||
            //     reservation.value.consultationClassification ===
            //       CONSULTATION_CLASSIFICATION.RE_EXAMINATION
            //   ) {
            //     formData.append('no_of_hospital_visits', '1');
            //   } else if (
            //     reservation.value.consultationClassification ===
            //     CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
            //   ) {
            //     const newNoOfVisit = reservation.value.customer?.noOfHospitalVisits + 1;
            //     formData.append('no_of_hospital_visits', newNoOfVisit);
            //   }
            //   // T_Customer. If the first visit date is NULL
            //   if (!reservation.value.customer?.firstVisitDate) {
            //     // T_Customer. Set today as the first visit date
            //     formData.append('first_visit_date', todaysDate);
            //     // T_Customer.T_Reservation for first visitor.Set practitioner
            //     formData.append('first_examination_staff', reservation.value.practitioner?.id);
            //   }
            //   // T_Customer. Set today as the last visit date
            //   formData.append('last_visit_date', todaysDate);

            //   //  T_Reservation. Set the current date and time for the first checkout date and time.
            //   reservationFormData.append('accounting_first_cooperation_datetime', todaysDate);

            //   //  T_Reservation. When the consultation category is re-examination
            //   if (
            //     reservation.value.consultationClassification ===
            //     CONSULTATION_CLASSIFICATION.RE_EXAMINATION
            //   ) {
            //     // T_Customer. Set today as return date
            //     formData.append('revisit_date', todaysDate);
            //     // T_Customer.T_Reservation for return visitor.Set practitioner
            //     formData.append('re_examination_staff', reservation.value.practitioner?.id);
            //   }
            // }
            // // T_Reservation. Set the current date and time as the accounting final linkage date and time
            // reservationFormData.append('accounting_final_cooperation_datetime', todaysDate);
            // reservationFormData.append('date', reservation.value.date);
            // reservationFormData.append(
            //   'starting_time',
            //   moment(reservation.value.startingTime, 'HH;mm"ss').format('HH:mm')
            // );
            // reservationFormData.append(
            //   'ending_time',
            //   moment(reservation.value.endingTime, 'HH;mm"ss').format('HH:mm')
            // );
            // reservationFormData.append('id', reservation.value.id);
            // reservationFormData.append('store_id', reservation?.value.store?.id);
            // if (reservation?.value.practitioner?.id) {
            //   reservationFormData.append('practitioner_id', reservation?.value.practitioner?.id);
            // }

            // const updateReservation = post(
            //   `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${reservation.value.id}`,
            //   reservationFormData
            // );
            // const updateCustomer = post(
            //   `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${reservation.value.customer.customerNumber}`,
            //   formData
            // );
            // axios.all([updateReservation, updateCustomer]).then(() => {
            // updateCustomer.then(() => {
              // createTemoraryTransaction(
              //   reservation.value?.customer.customerNumber ||
              //     reservation.value?.customer?.customerNumber,
              //   'reservation',
              //   reservation.value?.id,
              //   reservation.value?.store?.id,
              //   reservation?.value.practitioner?.id
              // )
              //   .then((res) => {
              //     apiLoading.value = false;
              //     // Added record to temporary sales = 仮売上高にレコードを追加
              //     toast.success('レジにデータ送信完了', {
              //       //Added record to temporary sales
              //       timeout: 3000,
              //       position: POSITION.BOTTOM_RIGHT
              //     });
              //     if (reservation.value.customer?.memberRemainingCoupon === 1) {
              //       toast.error(i18n.global.t('Errors.C103'), {
              //         //Added record to temporary sales
              //         timeout: 3000,
              //         position: POSITION.BOTTOM_RIGHT
              //       });
              //     }
              //     getReservation();
              //     // this.$emit("updateEvents");
              //     // this.$emit("setReservationId", this.reservation?.id);
              //   })
              //   .catch((err) => {
              //     console.log(err);
              //     apiLoading.value = false;
              //   });
            });
          // })
          // .catch((err) => {
          //   apiLoading.value = false;
          //   console.error(err);
          // });
      }
    };
    const getUpdate = (event: any) => {
      getReservation();
    };

    const openBackModal = () => {
      const backModalElement = document.getElementById('backModal') as HTMLElement;
      let backModal = bootstrap.Modal.getInstance(backModalElement);
      if (!backModal) {
        backModal = new bootstrap.Modal(backModalElement);
      }
      backModal.toggle();
    };

    const goBack = () => {
      store.dispatch('setReservationCalendarStoreId', reservation.value?.store?.id);
      store.dispatch('setReservationCalendarStoreDate', reservation.value?.date).then(() => {
        router.back();
      });
    };

    return {
      isCurrentOrPastDay,
      editButtonDisable,
      apiLoading,
      form: 'revisit',
      personalInfo,
      hasCustomer,
      reservation,
      medicalRecord,
      previousReservation,
      previousMedicalRecord,
      activeRegistrationButton,
      pay,
      onReservationCancelModal,
      // check,
      openBackModal,
      goBack,
      cancelReservation,
      ifMedicalDataIsPresent,
      getUpdate,
      goToReservationRegistration,
      nextTimeClick,
      setUpActiveRegistrationButtonOnCurrentTime,
      openLastReservation,
      isToday,
      updateReservation,
      temporaryTransaction
    };
  }
});
