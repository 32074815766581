import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cd3d304"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between table-sort" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "practitioner text-primary fw-bold" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  class: "card-bloc",
  style: {"padding":"20px 0 20px 0"}
}
const _hoisted_9 = {
  id: "dataTableSample",
  class: "table table-hover table-bordered dt-responsive",
  cellspacing: "0",
  width: "100%"
}
const _hoisted_10 = { class: "checkbox" }
const _hoisted_11 = {
  class: "checkbox",
  style: {"cursor":"pointer"}
}
const _hoisted_12 = ["onUpdate:modelValue", "checked", "onChange"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 0,
  class: "d-flex justify-content-center my-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.selectedStatus,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatus) = $event)),
          placeholder: "（状態を選択）",
          label: "name",
          options: _ctx.cancellationOptions
        }, {
          option: _withCtx(({ option }) => [
            _createTextVNode(_toDisplayString(option.name) + " ", 1),
            _createElementVNode("img", {
              class: "multi-select-option-icon",
              src: option.icon
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["modelValue", "options"]),
        _createElementVNode("button", {
          class: "btn btn-primary apply-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.applyChanges && _ctx.applyChanges(...args))),
          disabled: !_ctx.selectedStatus
        }, _toDisplayString(_ctx.$t('Tables.BeApplicable')), 9, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('Tables.Practitioner')), 1),
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.selectedPractitioner,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPractitioner) = $event)),
          placeholder: "（状態を選択）",
          label: "name",
          options: _ctx.practitionerOptions,
          onSelect: _ctx.filterReservationByPractitioner
        }, {
          option: _withCtx(({ option }) => [
            _createTextVNode(_toDisplayString(option.name) + " ", 1),
            _createElementVNode("img", {
              class: "multi-select-option-icon",
              src: option.icon
            }, null, 8, _hoisted_7)
          ]),
          _: 1
        }, 8, ["modelValue", "options", "onSelect"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("table", _hoisted_9, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  value: "block",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectTask) = $event)),
                  id: "reservationDateAndTime",
                  onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.check()))
                }, null, 544), [
                  [_vModelCheckbox, _ctx.selectTask]
                ])
              ]),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp1 && _ctx.selectedSort.field === 'reservationDateTime',
                'sort-up': !_ctx.sort.sortUp1 && _ctx.selectedSort.field === 'reservationDateTime'
              }]),
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp1'))),
                style: {"min-width":"80px"}
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.ReservationDateAndTime')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp2 && _ctx.selectedSort.field === 'customerName',
                'sort-up': !_ctx.sort.sortUp2 && _ctx.selectedSort.field === 'customerName'
              }]),
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp2')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.CustomerName')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp3 && _ctx.selectedSort.field === 'telephoneNumber',
                'sort-up': !_ctx.sort.sortUp3 && _ctx.selectedSort.field === 'telephoneNumber'
              }]),
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp3')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.TelephoneNumber')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp4 && _ctx.selectedSort.field === 'status',
                'sort-up': !_ctx.sort.sortUp4 && _ctx.selectedSort.field === 'status'
              }]),
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp4')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.Status')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp5 && _ctx.selectedSort.field === 'treatmentMenu',
                'sort-up': !_ctx.sort.sortUp5 && _ctx.selectedSort.field === 'treatmentMenu'
              }]),
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp5')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.TreatmentMenu')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp6 && _ctx.selectedSort.field === 'practitioner',
                'sort-up': !_ctx.sort.sortUp6 && _ctx.selectedSort.field === 'practitioner'
              }]),
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp6')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.Practitioner')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp7 && _ctx.selectedSort.field === 'managementMemo',
                'sort-up': !_ctx.sort.sortUp7 && _ctx.selectedSort.field === 'managementMemo'
              }]),
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp7')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.ManagementMemo')), 3),
              _createElementVNode("th", {
                class: _normalizeClass(["sorting", {
                'sort-down': _ctx.sort.sortUp8 && _ctx.selectedSort.field === 'reservationRegDateTime',
                'sort-up': !_ctx.sort.sortUp8 && _ctx.selectedSort.field === 'reservationRegDateTime'
              }]),
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSort($event.target.value, 'sortUp8')))
              }, _toDisplayString(_ctx.$t('TodayListTable.Head.ReservationRegistrationDate')), 3)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected, (selectedCustomer, i) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: i,
                style: _normalizeStyle({
              backgroundColor:
                selectedCustomer.reservationStatus === _ctx.RESERVATION_STATUS.CANCEL ? '#F0F2F0' : ''
            })
              }, [
                _createElementVNode("td", _hoisted_11, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input mt-1",
                    type: "checkbox",
                    id: "select",
                    "onUpdate:modelValue": ($event: any) => ((selectedCustomer.checked) = $event),
                    checked: selectedCustomer.checked,
                    onChange: ($event: any) => (_ctx.checkTask(selectedCustomer))
                  }, null, 40, _hoisted_12), [
                    [_vModelCheckbox, selectedCustomer.checked]
                  ])
                ]),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"white-space":"nowrap","cursor":"pointer"}
                }, _toDisplayString(selectedCustomer.date.slice(5).replace('-', '/')) + " " + _toDisplayString(_ctx.convertToStartingHH(selectedCustomer.startingTime)), 9, _hoisted_13),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"cursor":"pointer","max-width":"160px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                }, _toDisplayString(selectedCustomer?.customer?.customerNumber &&
                selectedCustomer.customer?.surname &&
                selectedCustomer.customer?.name
                  ? selectedCustomer.customer.surname + ' ' + selectedCustomer.customer.name
                  : selectedCustomer?.customer?.customerNumber && selectedCustomer.customer?.surname
                  ? selectedCustomer.customer.surname
                  : selectedCustomer?.reservationPerson
                  ? selectedCustomer?.reservationPerson
                  : '-'), 9, _hoisted_14),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"cursor":"pointer"}
                }, _toDisplayString(selectedCustomer?.phoneNumber ? selectedCustomer?.phoneNumber : '-'), 9, _hoisted_15),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"cursor":"pointer"}
                }, _toDisplayString(_ctx.reservationStatus[selectedCustomer?.reservationStatus]), 9, _hoisted_16),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"cursor":"pointer","max-width":"140px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                }, _toDisplayString(selectedCustomer?.treatmentMenu?.name), 9, _hoisted_17),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"cursor":"pointer","max-width":"140px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                }, _toDisplayString(selectedCustomer?.practitioner?.employeeNumber || '') + " " + _toDisplayString(selectedCustomer?.practitioner?.name.split(' ')?.[0]), 9, _hoisted_18),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  class: _normalizeClass({ 'text-center': !selectedCustomer?.managementMemo }),
                  style: {"cursor":"pointer","max-width":"100px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                }, _toDisplayString(String(selectedCustomer?.managementMemo).length === 7
                  ? selectedCustomer.managementMemo.slice(0, 6) + '...'
                  : selectedCustomer?.managementMemo
                  ? selectedCustomer.managementMemo.slice(0, 6)
                  : '-'), 11, _hoisted_19),
                _createElementVNode("td", {
                  onClick: ($event: any) => (_ctx.goToMedical(selectedCustomer, 'row', $event)),
                  style: {"cursor":"pointer"}
                }, _toDisplayString(_ctx.convertToMMDD(selectedCustomer.updatedDate)) + " " + _toDisplayString(_ctx.convertToHH(selectedCustomer.updatedDate)), 9, _hoisted_20)
              ], 4))
            }), 128))
          ])
        ]),
        (_ctx.selected?.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, "No Data"))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}