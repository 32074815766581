
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { store as post } from '@/core/api/api-service';
import { store } from '@/core/store';
import { CONSULTATION_CLASSIFICATION } from '@/core/store/enums';
import { consultationClassifications } from '@/core/store/constants';
import Modal from '../components/modals/Modal.vue';
import Loading from 'vue-loading-overlay';
import { Modal as BootstrapModal } from 'bootstrap';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import moment from 'moment';

export default defineComponent({
  name: 'ReviewPreliminaryExaminationSlip',
  components: {
    Modal,
    Loading
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const {
      getters: { reservation, registrationSteps, reservationRegistrationPageType }
    } = store;

    const customerData = ref(registrationSteps.customerRegistration || undefined);

    const symptomQuestions = ref(registrationSteps.symptomQuestions || undefined);
    const reviewQuestions = ref(registrationSteps.reviewQuestions || undefined);
    let errors: any = ref('');
    let apiLoading: any = ref(false);
    const showErrorModal: any = ref(false);

    const completeInformation = async () => {
      apiLoading.value = true;
      let customerValue: any = customerData.value;
      customerValue.registered_store = reservation?.store?.smaregiId;
      if (reservationRegistrationPageType === 'edit') {
        if (reservation?.store?.id) {
          customerValue.registered_store = reservation?.store?.smaregiId;
        }
        customerValue._method = 'PUT';
      }
      if (!customerValue.postal_code) {
        const element = document.getElementById('postalCodeAlertModal') as HTMLElement;
        const myModal = new BootstrapModal(element);
        myModal.show();
        apiLoading.value = false;
      }
      if (reservation?.lineId) {
        customerValue.line_id = reservation?.lineId;
      }
      if (!reservation?.customer?.customerNumber) {
        customerValue.last_visit_date = moment().format('YYYY-MM-DD');
        if (
          reservation?.consultationClassification === CONSULTATION_CLASSIFICATION.FIRST_VISIT &&
          reservation?.practitioner?.id
        ) {
          customerValue.first_examination_staff = reservation?.practitioner?.id;
        }
      }
      const urlType =
        reservationRegistrationPageType === 'edit'
          ? `customers/${reservation?.customer?.customerNumber}`
          : `customers`;
      let userFormData = new FormData();
      for (let key in customerValue) {
        userFormData.append(key, customerValue[key] || '');
      }
      if (
        reservation &&
        customerData.value &&
        symptomQuestions.value &&
        customerValue.postal_code
      ) {
        const response = await post(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlType}`,
          userFormData,
          {
            headers: {
              Authorization: 'Bearer ' + store.getters.token,
              'Content-Type': 'application/json'
            }
          }
        )
          .then((response) => {
            if (response?.status === 200) {
              const {
                data: { data }
              } = response;
              const { customerNumber, name, surname } = data;

              const updateCustomerNumberFormData = new FormData();
              updateCustomerNumberFormData.append('_method', 'PUT');
              updateCustomerNumberFormData.append('customer_number', customerNumber);
              const updateCustomerNumberAndReservationPersonFormData = new FormData();
              updateCustomerNumberAndReservationPersonFormData.append('_method', 'PUT');
              updateCustomerNumberAndReservationPersonFormData.append(
                'customer_number',
                customerNumber
              );
              updateCustomerNumberAndReservationPersonFormData.append(
                'reservation_person',
                `${surname} ${name}`
              );

              if (
                reservation.consultationClassification ===
                  CONSULTATION_CLASSIFICATION.FIRST_VISIT &&
                symptomQuestions.value &&
                reviewQuestions.value
              ) {
                Promise.all([
                  updateCustomerNumberAndReservationPersonNameInReservation(
                    updateCustomerNumberAndReservationPersonFormData
                  ),
                  updateMedicalRecord(updateCustomerNumberFormData),
                  createPreliminaryExamination(),
                  updateCustomerDetails(customerNumber),
                  createQuestionnairs(customerNumber)
                ])
                  .then((messages) => {
                    window.scrollTo(0, 0);
                    if (reservationRegistrationPageType === 'edit') {
                      toast.success('更新しました', {
                        timeout: 2000,
                        position: POSITION.BOTTOM_RIGHT
                      });
                    } else {
                      toast.success('登録しました', {
                        timeout: 2000,
                        position: POSITION.BOTTOM_RIGHT
                      });
                    }
                    gotoReservationDetail();
                  })
                  .catch((err) => {
                    apiLoading.value = false;
                    errors.value = err.response.data?.errors?.[0]?.title;
                  });
              } else if (
                reservation.consultationClassification ===
                  CONSULTATION_CLASSIFICATION.RE_EXAMINATION &&
                symptomQuestions.value
              ) {
                Promise.all([
                  updateCustomerNumberAndReservationPersonNameInReservation(
                    updateCustomerNumberAndReservationPersonFormData
                  ),
                  updateMedicalRecord(updateCustomerNumberFormData),
                  createPreliminaryExamination(),
                  updateCustomerDetails(customerNumber)
                ])
                  .then((messages) => {
                    window.scrollTo(0, 0);
                    if (reservationRegistrationPageType === 'edit') {
                      toast.success('更新しました', {
                        timeout: 2000,
                        position: POSITION.BOTTOM_RIGHT
                      });
                    } else {
                      toast.success('登録しました', {
                        timeout: 2000,
                        position: POSITION.BOTTOM_RIGHT
                      });
                    }

                    gotoReservationDetail();
                  })
                  .catch((err) => {
                    apiLoading.value = false;
                    errors.value = err.response.data?.errors?.[0]?.title;
                  });
              }
            }
          })
          .catch((err) => {
            apiLoading.value = false;
            errors.value = err.response.data?.errors?.[0]?.title;
          });
      }
    };

    const gotoReservationDetail = () => {
      apiLoading.value = false;
      store.dispatch('resetRegistrationSteps', {}).then(() => {
        router.push({
          name: 'ReservationDetail',
          params: {
            id: reservation.id
          }
        });
      });
    };
    const updateCustomerNumberAndReservationPersonNameInReservation = (
      updateCustomerNumberAndReservationPersonFormData: FormData
    ) => {
      return axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/updateCustomer/${reservation.id}`,
        updateCustomerNumberAndReservationPersonFormData,
        {
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            'Content-Type': 'application/json'
          }
        }
      );
    };
    const updateMedicalRecord = (updateCustomerNumberFormData: FormData) => {
      return axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${reservation.medicalRecord.id}`,
        updateCustomerNumberFormData,
        {
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            'Content-Type': 'application/json'
          }
        }
      );
    };
    const createPreliminaryExamination = () => {
      return axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/preliminary-examinations`,
        {
          medical_record_id: reservation.medicalRecord.id,
          type: reservation.symptom,
          format_id: symptomQuestions.value.themeName,
          format_version: symptomQuestions.value.formatVer,
          symptom_answer: JSON.stringify(symptomQuestions.value)
        },
        {
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            'Content-Type': 'application/json'
          }
        }
      );
    };
    const updateCustomerDetails = (customerNumber: number) => {
      const updateCustomerDetailFormData = new FormData();
      updateCustomerDetailFormData.append('_method', 'PUT');
      updateCustomerDetailFormData.append(
        'hv_not_possible',
        symptomQuestions.value.contents?.[7]?.answer === 'yes' ? '1' : '0'
      );
      updateCustomerDetailFormData.append(
        'metal_has',
        symptomQuestions.value.contents?.[8]?.answer === 'yes' ? '1' : '0'
      );
       if(symptomQuestions.value.contents?.[9]?.answer === 'no'){
        updateCustomerDetailFormData.append(
          'no_acupunture',
          symptomQuestions.value.contents?.[9]?.answer === 'yes' ? '1' : '0'
        );
      } else {
        updateCustomerDetailFormData.append(
          'no_acupunture',
          symptomQuestions.value.contents?.[9]?.answer === 'yes' ? '1' : '0'
        );
         updateCustomerDetailFormData.append(
          'hv_not_possible',
          symptomQuestions.value.contents?.[9]?.answer === 'yes' ? '1' : '0'
        );
      }
      if (reviewQuestions.value?.contents?.[1]?.answer) {
        updateCustomerDetailFormData.append(
          'notification_email_permission',
          reviewQuestions.value.contents?.[1]?.answer === 'true' ? '1' : '0'
        );
      }

      return axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${customerNumber}`,
        updateCustomerDetailFormData,
        {
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            'Content-Type': 'application/json'
          }
        }
      );
    };
    const createQuestionnairs = async (customerNumber: number) => {
      const questionnaireData: any = {
        customer_number: customerNumber,
        medical_record_id: reservation.medicalRecord.id,
        inflow_route_classification: reviewQuestions.value.contents?.[0]?.answer,
        search_keyword:
          (await reviewQuestions.value.contents?.[0].options.filter(
            (option: any) =>
              option?.text !== 'その他' &&
              reviewQuestions?.value?.contents?.[0]?.answer === option?.value &&
              option?.children
          )?.[0]?.children.answer) || '',
        other_inflow_route:
          (await reviewQuestions.value.contents?.[0].options.filter(
            (option: any) =>
              option.text === 'その他' &&
              reviewQuestions.value.contents?.[0].answer === option.value &&
              option?.children
          )?.[0]?.children.answer) || '',

        photographing_permission: reviewQuestions.value.contents?.[2]?.answer,
        question_answer: JSON.stringify(reviewQuestions.value)
      };
      let questionnaireFormData = new FormData();
       for (let key in questionnaireData) {
        questionnaireFormData.append(
          key,
          key === 'photographing_permission' ? questionnaireData[key] === 'true'
              ? 1
              : 0
            : questionnaireData[key]
        );
      }
      return axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/questionnaires`,
        questionnaireFormData,
        {
          headers: {
            Authorization: 'Bearer ' + store.getters.token,
            'Content-Type': 'application/json'
          }
        }
      );
    };

    const goBack = () => {
      router.push({ name: 'PreliminaryExaminationSteps', query: { step: 4 } });
    };
    const isSubmitButtonDisabled = computed(() => {
      let disabled;
      if (apiLoading.value === true) {
        disabled = true;
      } else {
        disabled = false;
      }
      return disabled;
    });
    return {
      apiLoading,
      showErrorModal,
      errors,
      reservation,
      consultationClassifications,
      customerData,
      symptomQuestions,
      reviewQuestions,
      completeInformation,
      goBack,
      isSubmitButtonDisabled
    };
  }
});
