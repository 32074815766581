
import { defineComponent, ref, toRefs, PropType } from 'vue';
import * as bootstrap from 'bootstrap';
import axios from 'axios';
import { store as post } from '@/core/api/api-service';
import ReservationCancleModal from '../components/modals/ReservationCancleModal.vue';
import Loading from 'vue-loading-overlay';

import {
  RESERVATION_TYPE,
  RESERVATION_STATUS,
  TASK_STATUS,
  CONSULTATION_CLASSIFICATION,
  SYMPTOMS
} from '@/core/store/enums';
import {
  reservationRoutes,
  reservationStatus,
  reservationTypes,
  symptoms,
  taskStatus
} from '@/core/store/constants';
import { createTemoraryTransaction } from '@/core/api/transaction-service';
import moment from 'moment';
import { Modal as BootstrapModal } from 'bootstrap';
import { getFutureReservations } from '@/core/api/accounting-service';
import { useToast, POSITION } from 'vue-toastification';
import AlertModal from '@/components/modals/AlertModal.vue';
import { days } from 'alga-js/types/date/dayDate';

export default defineComponent({
  name: 'CalendarDetail',
  components: { Loading, ReservationCancleModal, AlertModal },
  emits: ['updateEvents', 'setCustomerData', 'setReservationId', 'startLoading', 'goToNextDay'],
  props: {
    reservation: {
      // required: false,
      type: Object
      // type: String as PropType<"default" | "previousMonth" | "nextMonth">,
      // default: "default",
    }
  },
  watch: {
    reservation: function (newProp, oldProp) {
      this.startTime = moment.utc(newProp?.start).format('HH:mm') || '';
      this.endTime = moment.utc(newProp?.end).format('HH:mm') || '';
      this.eventDate = moment.utc(newProp?.start).format('YYYY-MM-DD');
      this.date = moment.utc(newProp?.start).format('LL (dd)') || '';
      this.remarks = newProp.extendedProps?.remarks || '';
      this.reservedBy = newProp.extendedProps?.practitioner?.name;
      this.remainingCoupon = newProp.extendedProps.customer?.remainingCoupon;
      this.memberRemainingCoupon = newProp.extendedProps.customer?.memberRemainingCoupon;
      this.noOfVisits = newProp.extendedProps.customer?.noOfHospitalVisits;
      this.receivable = newProp.extendedProps.customer?.accountsReceivable;
      this.administrativeNote =
        newProp?.extendedProps?.detail ||
        newProp?.extendedProps?.details ||
        newProp.extendedProps.managementMemo;
      this.reservationType = newProp.extendedProps.type;
      this.status = newProp.extendedProps.status;
      this.backgroundColor = newProp.extendedProps.treatmentMenu?.backgroundColor
        ? newProp.extendedProps.treatmentMenu?.backgroundColor + '33'
        : '#fcfcfc';
      this.isPassed(newProp);
      this.checkCancelled(newProp);
      this.checkStatusReception(newProp);
    }
  },
  data() {
    moment.locale('ja');
    return {
      apiLoading: false,
      disabledStatusIfPassedHoliday: false,
      typeOfreservation: '',
      previousMedicalRecord: '',
      previousReservation: { medicalRecord: { id: '' } },
      RESERVATION_STATUS: RESERVATION_STATUS,
      RESERVATION_TYPE: RESERVATION_TYPE,
      TASK_STATUS: TASK_STATUS,
      reservationRoutes: reservationRoutes,
      reservationTypes: reservationTypes,
      reservationStatus: reservationStatus,
      symptoms: symptoms,
      taskStatus: taskStatus,
      eventDate: moment.utc(this.reservation?.start).format('YYYY-MM-DD'),
      date: moment.utc(this.reservation?.start).format('LL (dd)'),
      startTime: moment.utc(this.reservation?.start).format('HH:mm') || '',
      endTime: moment.utc(this.reservation?.end).format('HH:mm') || '',
      reservationType: this.reservation?.extendedProps.type,
      status: this.reservation?.extendedProps.status,
      memberRemainingCoupon: this.reservation?.extendedProps.customer?.memberRemainingCoupon,
      noOfVisits: this.reservation?.extendedProps.customer?.noOfHospitalVisits,
      receivable: this.reservation?.extendedProps.customer?.accountsReceivable,
      administrativeNote:
        this.reservation?.extendedProps?.detail ||
        this.reservation?.extendedProps?.details ||
        this.reservation?.extendedProps?.managementMemo,
      reservedBy: this.reservation?.extendedProps.practitioner?.name,
      remarks: this.reservation?.extendedProps?.remarks || '',
      backgroundColor: this.reservation?.extendedProps.treatmentMenu?.backgroundColor
        ? this.reservation?.extendedProps.treatmentMenu?.backgroundColor + '33'
        : '#fcfcfc',
      remainingCoupon: this.reservation?.extendedProps.customer?.remainingCoupon,
      passedEvent: true,
      isCancelled: false,
      statusIsReception: false,
      today: moment().format('YYYY-MM-DD')
      // passedEvent: moment
      //   .utc(this.reservation?.end)
      //   .isAfter(moment.utc(Date.now())),
      // reservationType: this.getReservationType(this.reservation),
      // reservationType: "",
    };
  },
  computed: {
    isCurrentOrPastDay: function () {
      return moment(this.eventDate).isSameOrBefore(moment(), 'day');
    },
    isToday: function () {
      return moment(this.eventDate).isSame(moment(), 'day');
    }
  },
  mounted() {
    this.isPassed(this.reservation);
    this.checkCancelled(this.reservation);
    this.checkStatusReception(this.reservation);
    // this.oldMedical(this.reservation);
  },
  methods: {
    async oldMedical(reservation: any) {
      if (
        reservation?.extendedProps?.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      ) {
        try {
          const reservationResData = await axios(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/previous`,
            {
              headers: { Authorization: 'Bearer ' + this.$store.getters.token },
              params: {
                reservationId: reservation?._def?.publicId,
                customerNumber: reservation?.extendedProps?.customer.customerNumber
              }
            }
          );
          if (reservationResData.status === 200) {
            if (reservationResData.data?.data) {
              this.previousReservation = reservationResData.data?.data;
              try {
                const medicalRecordResponse = await axios(
                  `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${this.previousReservation.medicalRecord?.id}`,
                  {
                    headers: {
                      Authorization: 'Bearer ' + this.$store.getters.token
                    }
                  }
                );
                if (medicalRecordResponse.status === 200) {
                  this.previousMedicalRecord = medicalRecordResponse.data.data;
                }
              } catch {
                return;
              }
            }
          }
        } catch {
          return;
        }
      }
    },
    openCancelModal() {
      const element = document.getElementById('reservationCancleModal') as HTMLElement;
      const myModal = new BootstrapModal(element);
      myModal.show();
    },
    cancelReservation($event: number) {
      const elementH = document.getElementById('reservationCancleModal') as HTMLElement;

      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('date', this.eventDate);
      formData.append('starting_time', this.startTime);
      formData.append('ending_time', this.endTime);
      formData.append('id', this.reservation?.id);
      formData.append('cancellation_type', $event.toString());
      formData.append('store_id', this.reservation?.extendedProps?.store?.id);
      if (this.reservation?.extendedProps?.practitioner?.id) {
        formData.append('practitioner_id', this.reservation?.extendedProps.practitioner.id);
      }
      if (this.reservationType === 1) {
        formData.append('reservation_status', '6');
        return post(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${this.reservation?.id}`,
          formData
        )
          .then((res) => {
            if (res.status === 200) {
              const toast = useToast();
              toast.success('キャンセルしました。', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
              this.$emit('updateEvents');
              const myModal = BootstrapModal.getInstance(elementH);
              myModal?.hide();
            }
          })
          .catch((err) => {
            if (err) {
              const myModal = BootstrapModal.getInstance(elementH);
              myModal?.hide();
            }
          });
      } else if (this.reservationType === 2) {
        formData.append('status', '3');
        return post(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/tasks/${this.reservation?.id}`,
          formData
        )
          .then((res) => {
            if (res.status === 200) {
              const toast = useToast();
              toast.success('キャンセルしました。', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
              this.$emit('updateEvents');
              const myModal = BootstrapModal.getInstance(elementH);
              myModal?.hide();
            }
          })
          .catch((err) => {
            const myModal = BootstrapModal.getInstance(elementH);
            myModal?.hide();
          });
      } else {
        formData.append('status', '0');
        return post(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/public-holidays/${this.reservation?.id}`,
          formData
        )
          .then((res) => {
            if (res.status === 200) {
              const toast = useToast();
              toast.success('キャンセルしました。', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
              this.$emit('updateEvents');
              const myModal = BootstrapModal.getInstance(elementH);
              myModal?.hide();
            }
          })
          .catch((err) => {
            const myModal = BootstrapModal.getInstance(elementH);
            myModal?.hide();
          });
      }
    },
    changeReservationStatus() {
      if (this.reservation?.extendedProps?.customer?.customerNumber) {
        
        if (!this.reservation?.extendedProps?.customer?.name || !this.reservation?.extendedProps?.customer?.katakanaName || !this.reservation?.extendedProps?.customer?.smaregiId) {
          const toast = useToast();
          toast.error('先に顧客情報を修正してください。', {
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
          return;
        }

        const customerNumberValue = this.reservation?.extendedProps?.customer?.customerNumber;
        axios
          .get(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/each-sales?customerNumber=${customerNumberValue}`,
            {
              headers: {
                Authorization: 'Bearer ' + this.$store.getters.token,
                'Content-Type': 'application/json'
              }
            }
          )
          .then(async (res) => {
            const toast = useToast();
            if (this.reservation?.extendedProps?.consultationClassification === 4) {
              toast.error('受診区分を初診・継続治療・再診のいずれかに修正してください.', {
                timeout: 3000,
                position: POSITION.BOTTOM_RIGHT
              });
            }
            if (!this.reservation?.extendedProps?.samePersonConfirmed) {
              toast.error(this.$t('Errors.E101'), {
                timeout: 3000,
                position: POSITION.BOTTOM_RIGHT
              });
              return;
            }

            if (res?.data?.data.length > 0) {
              toast.success('次月の物販をオススメする時期です', {
                timeout: 3000,
                position: POSITION.BOTTOM_RIGHT
              });
            }
            if (
              this.reservation?.extendedProps?.consultationClassification !== 4 &&
              this.reservation?.extendedProps?.samePersonConfirmed &&
              res?.data?.data
            ) {
              const formData = new FormData();
              formData.append('store_id', this.reservation?.extendedProps?.store?.id);
              formData.append('_method', 'PUT');
              formData.append('date', this.eventDate);
              formData.append('starting_time', this.startTime);
              formData.append('ending_time', this.endTime);
              formData.append('id', this.reservation?.id);
              formData.append('reservation_status', '3');
              formData.append('practitioner_id', this.reservation?.extendedProps.practitioner.id);
              if (this.reservation?.extendedProps?.lineId) formData.append('line_id', this.reservation?.extendedProps?.lineId)
              axios
                .post(
                  `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${this.reservation?.id}`,
                  formData,
                  {
                    headers: {
                      Authorization: 'Bearer ' + this.$store.getters.token,
                      'Content-Type': 'application/json'
                    }
                  }
                )
                .then(async (responseFromApi) => {
                  if (responseFromApi.status === 200) {
                    this.$emit('startLoading');
                    this.statusIsReception = true;
                    if (this.reservation?.extendedProps.customer) {
                      let formData1 = new FormData();
                      formData1.append('_method', 'PUT');
                      formData1.append('last_visit_date', moment().format('YYYY-MM-DD'));
                      // if (
                      //   this.reservation?.extendedProps
                      //     .consultationClassification ===
                      //   CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
                      // ) {
                      //   const updatedNoOfVisits =
                      //     this.reservation.extendedProps.customer
                      //       .noOfHospitalVisits + 1;
                      //   formData1.append(
                      //     "no_of_hospital_visits",
                      //     String(updatedNoOfVisits)
                      //   );
                      // }
                      // await axios.post(//REMOVED THIS CODE BECAUSE IT WAS HANDLED FORM BACKEND
                      //   `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${this.reservation?.extendedProps.customer.customerNumber}`,
                      //   formData1,
                      //   {
                      //     headers: {
                      //       Authorization: 'Bearer ' + this.$store.getters.token
                      //     }
                      //   }
                      // ); //REMOVED THIS CODE BECAUSE IT WAS HANDLED FORM BACKEND
                    }
                  }
                })
                .then(() => {
                  this.$emit('setReservationId', this.reservation?.id);
                  this.$emit('updateEvents');
                })
                .catch((err) => {
                  const toast = useToast();
                  toast.error(err.response.data?.errors[0].title, {
                    timeout: 3000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                });
            }
          });
      } else {
        const toast = useToast();
        if (this.reservation?.extendedProps?.consultationClassification === 4) {
          toast.error('受診区分を初診・継続治療・再診のいずれかに修正してください.', {
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
        }
        if (!this.reservation?.extendedProps?.samePersonConfirmed) {
          toast.error(this.$t('Errors.E101'), {
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
          return;
        }
        if (
          this.reservation?.extendedProps?.consultationClassification !== 4 &&
          this.reservation?.extendedProps?.samePersonConfirmed
        ) {
          const formData = new FormData();
          formData.append('store_id', this.reservation?.extendedProps?.store?.id);
          formData.append('_method', 'PUT');
          formData.append('date', this.eventDate);
          formData.append('starting_time', this.startTime);
          formData.append('ending_time', this.endTime);
          formData.append('id', this.reservation?.id);
          formData.append('reservation_status', '3');
          formData.append('practitioner_id', this.reservation?.extendedProps.practitioner.id);
          if (this.reservation?.extendedProps?.lineId) formData.append('line_id', this.reservation?.extendedProps?.lineId) 
          axios
            .post(
              `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${this.reservation?.id}`,
              formData,
              {
                headers: {
                  Authorization: 'Bearer ' + this.$store.getters.token,
                  'Content-Type': 'application/json'
                }
              }
            )
            .then(async (res) => {
              if (res.status === 200) {
                this.$emit('startLoading');
                this.statusIsReception = true;
                if (this.reservation?.extendedProps.customer) {
                  let formData = new FormData();
                  formData.append('_method', 'PUT');
                  formData.append('last_visit_date', moment().format('YYYY-MM-DD'));
                  // if (
                  //   this.reservation?.extendedProps
                  //     .consultationClassification ===
                  //   CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
                  // ) {
                  //   const updatedNoOfVisits =
                  //     this.reservation.extendedProps.customer
                  //       .noOfHospitalVisits + 1;
                  //   formData.append(
                  //     "no_of_hospital_visits",
                  //     String(updatedNoOfVisits)
                  //   );
                  // }
                  await axios.post(
                    `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${this.reservation?.extendedProps.customer.customerNumber}`,
                    formData,
                    {
                      headers: {
                        Authorization: 'Bearer ' + this.$store.getters.token
                      }
                    }
                  );
                }
              }
            })
            .then(() => {
              this.$emit('setReservationId', this.reservation?.id);
              this.$emit('updateEvents');
            });
        }
      }
    },
    async goToReservationRegistration() {
      this.apiLoading = true;
      await this.oldMedical(this.reservation);
      this.$store.dispatch('setSecondBackId', 'undefined');
      this.$store.dispatch('setSecondBackButton', false);
      this.$router.push({
        name: 'ReservationRegistration',
        params: {
          isFromEdit: 'true',
          hasMedicalRecord: this.reservation?.extendedProps?.medicalRecord?.id
            ? 'true'
            : this.previousMedicalRecord
            ? 'true'
            : 'false',
          id: this.reservation?.id,
          date: this.reservation?.extendedProps.reservationDate.date,
          startHour: moment(this.reservation?.extendedProps.startingTime, 'HH:mm:ss').format(
            'HH:mm'
          ),
          endHour:
            moment(this.reservation?.extendedProps.endingTime, 'HH:mm:ss').format('HH:mm') || '',
          store: this.reservation?.extendedProps.store?.id || '',
          practitioner: this.reservation?.extendedProps.practitioner?.id || '',
          reservation_type: this.reservation?.extendedProps.type,
          reservation_status: this.reservation?.extendedProps.reservationStatus,
          reservation_person: this.reservation?.extendedProps.reservationPerson,
          phone: this.reservation?.extendedProps.phone,
          same_person_confirmation: this.reservation?.extendedProps.samePersonConfirmed ? 1 : 0,
          symptom: this.reservation?.extendedProps.symptom,
          management_memo: this.reservation?.extendedProps.managementMemo,
          treatment_menu_id: this.reservation?.extendedProps.treatmentMenu?.id,
          task_type: this.reservation?.extendedProps.taskType?.id,
          consultation_classification: this.reservation?.extendedProps.consultationClassification,
          customerNumber: this.reservation?.extendedProps.customer?.customerNumber,
          absence_type_id: this.reservation?.extendedProps.absenceType?.id,
          status: this.reservation?.extendedProps.status,
          detail:
            this?.reservation?.extendedProps?.type == 2
              ? this.reservation?.extendedProps?.detail
              : this?.reservation?.extendedProps?.type == 3
              ? this?.reservation?.extendedProps?.details
              : '',
          reservationRoute: this.reservation?.extendedProps?.reservationRoute || '',
          block: this.reservation?.extendedProps?.block ? '1' : '0'
        },
        query: { type: 'reserve' }
      });
    },
    gotToMedicalRecord() {
      this.$router.push({
        name: 'ReservationDetail',
        params: {
          id: this.reservation?.id
        }
      });
    },
    isPassed(reservation: any) {
      const reservationDate = reservation?.extendedProps.reservationDate;
      if (reservationDate) {
        reservationDate.formattedStartDateTime.isAfter(moment())
          ? (this.passedEvent = true)
          : (this.passedEvent = false);
      }
    },
    checkCancelled(reservation: any) {
      if (reservation?.extendedProps?.type === 3) {
        this.isCancelled = moment(
          reservation?.extendedProps.reservationDate.date,
          'YYYY-MM-DD'
        ).isBefore(moment(`${moment().format('YYYY-MM-DD')}`, 'YYYY-MM-DD'));
        // if (dateIsPassed) {
        //   this.isCancelled = true;
        // } else {
        //   this.isCancelled = false;
        // }
      } else {
        if (reservation?.extendedProps.type === 1) {
          reservation.extendedProps.reservationStatus === RESERVATION_STATUS.CANCEL
            ? (this.isCancelled = true)
            : (this.isCancelled = false);
        } else if (reservation?.extendedProps.type === 2) {
          reservation.extendedProps.status === 3
            ? (this.isCancelled = true)
            : (this.isCancelled = false);
        } else {
          reservation.extendedProps.status === 0
            ? (this.isCancelled = true)
            : (this.isCancelled = false);
        }
      }
    },
    checkStatusReception(reservation: any) {
      reservation.extendedProps.reservationStatus === RESERVATION_STATUS.RECEPTION
        ? (this.statusIsReception = true)
        : (this.statusIsReception = false);
    },
    newForm(reservation: any) {
      const toast = useToast();
      if (!reservation?.extendedProps?.customer?.customerNumber) {
        toast.error(this.$t('Errors.E102'), {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      } else if (this.$store.getters.user.currentAssignment.length == 0) {
        toast.error('こちらのスタッフは店舗配属されておりません。', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        })
        return;
      } else {
        this.$store.dispatch('setSecondBackButton', true);
        this.$store.dispatch('setSecondBackId', this.reservation?._def.publicId);
        this.$store.dispatch(
          'setReservationCalendarStoreDate',
          moment(reservation.start).add(1, 'days').format('YYYY-MM-DD')
        );
        this.$emit('setCustomerData', this.reservation?.extendedProps);
        this.$emit('goToNextDay');
      }
    },
    redirectToCustomerDetail(reservationData: any) {
      this.$router.push({
        name: 'CustomerDetails',
        params: {
          id: `edit?id=?${reservationData?.extendedProps?.customer?.customerNumber}`,
          customerNumberFromList: `?customerNumber=${reservationData?.extendedProps?.customer?.customerNumber}`
        }
      });
    },
    temporaryTransaction(reservation: any) {
      this.apiLoading = true;
      const toast = useToast();
      createTemoraryTransaction(
        reservation.extendedProps.customer.customerNumber,
        // reservation?.extendedProps?.store?.id,
        this.$store.getters?.user?.currentAssignment?.[0]?.storeId, //sending storeId of current user
        'reservation',
        reservation.id,
        reservation?.extendedProps?.practitioner?.id
      )
        .then((res) => {
          // this.apiLoading = false;
          // Added record to temporary sales = 仮売上高にレコードを追加
          toast.success('レジにデータ送信完了', {
            //Added record to temporary sales
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
          if (reservation.extendedProps.customer?.memberRemainingCoupon === 1) {
            toast.error(this.$t('Errors.C103'), {
              //Added record to temporary sales
              timeout: 3000,
              position: POSITION.BOTTOM_RIGHT
            });
          }
          this.$emit('updateEvents');
          this.$emit('setReservationId', this.reservation?.id);
        })
        .catch((err) => {
          toast.error(`${err.response.data?.errors?.[0]?.title}`, {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
          this.apiLoading = false;
        });
    },
    async pay(reservation: any) {
      const toast = useToast();
      // Error if there is no customer number
      if (!reservation?.extendedProps?.customer?.customerNumber) {
        toast.error(this.$t('Errors.E102'), {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      if(this.$store.getters.user.currentAssignment.length == 0) {
        toast.error('こちらのスタッフは店舗配属されておりません。', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      // If the next reservation date is undecided, Alert will be displayed.

      // Accounting cooperation is not possible if the reservation date is past the day
      // Today> The date of the selected reservation slot (V_calendar.date) is error E102

      if (
        moment(moment().format('YYYY-MM-DD')).isAfter(
          moment(reservation?.extendedProps?.reservationDate?.date, 'YYYY-MM-DD')
        ) &&
        reservation?.extendedProps?.reservationStatus !== RESERVATION_STATUS.RECEPTION
      ) {
        // toast.error(this.$t('Errors.E102'), {
        toast.error('予約日が当日を過ぎています', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      // If the next reservation date is undecided, Alert will be displayed.

      if (
        reservation?.extendedProps?.customer?.customerNumber &&
        reservation?.extendedProps?.reservationDate?.date
      ) {
        // Chdck future reservations
        getFutureReservations(
          reservation?.extendedProps?.customer?.customerNumber,
          reservation?.extendedProps?.reservationDate?.date
        )
          .then((res) => {
            if (res.length === 0) {
              // toast.error(this.$t('Errors.C100'), {
              //   timeout: 3000,
              //   position: POSITION.BOTTOM_RIGHT
              // });
              // show alert modal for next reservation
              const alertModalElement = document.getElementById('alertModal') as HTMLElement;
              const alertModal = new BootstrapModal(alertModalElement);
              alertModal.show();
            } else {
              this.temporaryTransaction(reservation);
            }
            // const todaysDate = moment().format('YYYY-MM-DD');
            // let formData = new FormData();
            // formData.append('_method', 'PUT');
            // let reservationFormData = new FormData();
            // reservationFormData.append('_method', 'PUT');
            // reservationFormData.append('reservation_status', `${RESERVATION_STATUS.TOTAL}`);
            // // T_Reserved. If the first accounted date and time is NULL
            // if (!reservation.extendedProps?.firstAccountedDatetime) {
            //   // V_Calendar. If the consultation category is revisit (3), set T_Customer.Number of hospital visits to 1 (reset). Other than that, T_customer. Increase the number of hospital visits by 1.
            //   if (
            //     reservation.extendedProps?.consultationClassification ===
            //       CONSULTATION_CLASSIFICATION.FIRST_VISIT ||
            //     reservation.extendedProps?.consultationClassification ===
            //       CONSULTATION_CLASSIFICATION.RE_EXAMINATION
            //   ) {
            //     formData.append('no_of_hospital_visits', '1');
            //   } else if (
            //     reservation.extendedProps?.consultationClassification ===
            //     CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
            //   ) {
            //     const newNoOfVisit = reservation.extendedProps?.customer?.noOfHospitalVisits + 1;
            //     formData.append('no_of_hospital_visits', newNoOfVisit);
            //   }
            //   // T_Customer. If the first visit date is NULL
            //   if (!reservation.extendedProps?.customer?.firstVisitDate) {
            //     // T_Customer. Set today as the first visit date
            //     formData.append('first_visit_date', todaysDate);
            //     // T_Customer.T_Reservation for first visitor.Set practitioner
            //     formData.append(
            //       'first_examination_staff',
            //       reservation.extendedProps.practitioner?.id
            //     );
            //   }
            //   // T_Customer. Set today as the last visit date
            //   formData.append('last_visit_date', todaysDate);

            //   //  T_Reservation. Set the current date and time for the first checkout date and time.
            //   reservationFormData.append('accounting_first_cooperation_datetime', todaysDate);

            //   //  T_Reservation. When the consultation category is re-examination
            //   if (
            //     reservation.extendedProps?.consultationClassification ===
            //     CONSULTATION_CLASSIFICATION.RE_EXAMINATION
            //   ) {
            //     // T_Customer. Set today as return date
            //     formData.append('revisit_date', todaysDate);
            //     // T_Customer.T_Reservation for return visitor.Set practitioner
            //     formData.append('re_examination_staff', reservation.extendedProps.practitioner?.id);
            //   }
            // }
            // // T_Reservation. Set the current date and time as the accounting final linkage date and time
            // reservationFormData.append('accounting_final_cooperation_datetime', todaysDate);
            // reservationFormData.append('date', reservation.extendedProps?.reservationDate?.date);
            // reservationFormData.append(
            //   'starting_time',
            //   moment(reservation.extendedProps?.reservationDate?.start, 'HH;mm"ss').format('HH:mm')
            // );
            // reservationFormData.append(
            //   'ending_time',
            //   moment(reservation.extendedProps?.reservationDate?.end, 'HH;mm"ss').format('HH:mm')
            // );
            // reservationFormData.append('id', reservation?.id);
            // reservationFormData.append('store_id', reservation?.extendedProps?.store?.id);
            // if (reservation?.extendedProps?.practitioner?.id) {
            //   reservationFormData.append(
            //     'practitioner_id',
            //     reservation?.extendedProps?.practitioner?.id
            //   );
            // }

            // const updateReservation = post(
            //   `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/${reservation.id}`,
            //   reservationFormData
            // );
            // const updateCustomer = post(
            //   `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${reservation.extendedProps.customer.customerNumber}`,
            //   formData
            // );
            // axios.all([updateReservation, updateCustomer])
            // updateCustomer.then(() => {

            // })
            // .catch((err) => {
            //   this.apiLoading = false;
            //   toast.error(`${err.response.data?.errors?.[0]?.title}`, {
            //     timeout: 2000,
            //     position: POSITION.BOTTOM_RIGHT
            //   });
            //   console.log(err);
            // });
          })
          .catch((err) => {
            this.apiLoading = false;
            console.error(err);
          });
      }
    }
  }
});
